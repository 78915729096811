import React, { useState } from "react";
import { Input, Modal } from "antd";

const CreateNewTemplateModal = (props) => {
  const {
    templateData,
    defaultSectionEntities,
    completionSectionEntities,
    handleCreateNewTemplate,
    showCreateNewTemplateModal,
    setShowCreateNewTemplateModal,
    // createNewTemplateFromModal,
  } = props;

  const [templateName, setTemplateName] = useState("");

  const createNewTemplateFromModal = () => {
    const { _id, centre, category } = templateData;

    let newTemplateData = {
      name: templateName,
      centre,
      parent_template: _id,
      category: category._id,
      default_sections: defaultSectionEntities,
      completion_sections: completionSectionEntities,
    };

    handleCreateNewTemplate(newTemplateData);
  };
  return (
    <>
      <Modal
        title="Save as new template"
        visible={showCreateNewTemplateModal}
        onOk={createNewTemplateFromModal}
        onCancel={() => setShowCreateNewTemplateModal(false)}
      >
        <div className="mb-2">Template name</div>
        <Input
          onChange={(e) => setTemplateName(e.target.value)}
          placeholder="Name your new template"
        />
      </Modal>
    </>
  );
};

export default CreateNewTemplateModal;
