import React, { Component } from "react";
import { Result } from "antd";

import "./styles.scss";
class index extends Component {
  render() {
    return (
      <div className="page-empty">
        <Result
          status="404"
          title="We're coming soon"
          subTitle="Check in later"
        />
      </div>
    );
  }
}

export default index;
