import { uninitialized, initialized, success, error } from "./documents.types";

import {
  DOCUMENTS_LIST_REQUEST,
  DOCUMENTS_LIST_RESPONSE,
  DOCUMENTS_ZIP_REQUEST,
  DOCUMENTS_ZIP_RESPONSE,
} from "./documents.types";

const initialState = {
  documentList: {
    status: uninitialized,
    error: null,
    data: [],
  },
  documentsZip: {
    status: uninitialized,
    error: null,
    data: [],
  },
};

export function documentsReducer(state = initialState, action) {
  switch (action.type) {
    case DOCUMENTS_LIST_REQUEST:
      return {
        ...state,
        documentList: {
          ...state.documentList,
          status: initialized,
        },
      };
    case DOCUMENTS_LIST_RESPONSE:
      if (action.payload.error) {
        return {
          ...state,
          documentList: {
            ...state.documentList,
            status: error,
            error: action.payload.error,
          },
        };
      }
      return {
        ...state,
        documentList: {
          ...state.documentList,
          status: success,
          error: null,
          data: action.payload.documents,
        },
      };

    case DOCUMENTS_ZIP_REQUEST:
      return {
        ...state,
        documentsZip: {
          ...state.documentsZip,
          status: initialized,
        },
      };
    case DOCUMENTS_ZIP_RESPONSE:
      if (action.payload.error) {
        return {
          ...state,
          documentsZip: {
            ...state.documentsZip,
            status: error,
            error: action.payload.error,
          },
        };
      }
      return {
        ...state,
        documentsZip: {
          ...state.documentsZip,
          status: success,
          error: null,
          data: action.payload.documents,
        },
      };

    default:
      return state;
  }
}
