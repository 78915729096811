import moment from "moment";

export function composeSurveyQA(data) {
  const sleepDiarySurvey = [];
  const pittsburghSurvey = [];
  const epworthSurvey = [];
  const berlinSurvey = [];
  const npsSurvey = [];

  data.responses.forEach((s) => {
    if (s.survey.name === "Sleep diary/soft note - v1") {
      sleepDiarySurvey.push(s);
    } else if (s.survey.name === "Pittsburgh Sleep Quality Index") {
      pittsburghSurvey.push(s);
    } else if (s.survey.name === "Epworth Sleepiness Scale") {
      epworthSurvey.push(s);
    } else if (s.survey.name === "General - NPS") {
      npsSurvey.push(s);
    } else {
      berlinSurvey.push(s);
    }
  });

  // console.log("all surveys", sleepDiarySurvey, pittsburghSurvey);

  return {
    sleepDiarySurvey: constructMultiSurveyDataSource(sleepDiarySurvey) || [],
    // pittsburghSurvey: constructSimpleSurveyData(pittsburghSurvey) || [],
    // epworthSurvey: constructSimpleSurveyData(epworthSurvey) || [],
    // berlinSurvey: constructSimpleSurveyData(berlinSurvey) || [],
    npsSurvey: constructNPSDataSource(npsSurvey) || [],
  };
}

function constructWithReponse(surveyData) {
  const d = surveyData.survey.survey.pages.map((m) => m.elements).flat();
  for (let r in surveyData.response) {
    const i = d.findIndex((m) => m.name === r);
    d[i] = { ...d[i], answer: surveyData.response[r] };
  }
  return d;
}

function constructMultiSurveyDataSource(surveyData) {
  const sleepDiaryData = surveyData.map((m) => {
    return surveyWithDate(m);
  });

  function surveyWithDate(surveyData) {
    const dateFormat = moment(surveyData.modified_at).format("l");
    return {
      date: dateFormat,
      elements: constructWithReponse(surveyData),
    };
  }
  return sleepDiaryData;
}

function constructNPSDataSource(surveyData) {
  const npsData = surveyData.map((m) => {
    return constructWithReponse(m);
  });
  return npsData;
}
