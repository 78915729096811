import { apiInstance } from "./axios";

export function updateNotes(test_id, data) {
  return apiInstance
    .patch(`tests/${test_id}`, {
      notes: data,
    })
    .then((res) => {
      return res.data.test;
    })
    .catch((err) => ({
      error: true,
    }));
}
