import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import "./styles.scss";

const TestResults = () => {
  return <Redirect to={{ pathname: "/study/summary" }} />;
};

const mapStateToProps = (state) => {
  return {
    system: state.system,
  };
};

export default connect(mapStateToProps)(TestResults);
