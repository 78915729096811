import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Card, Timeline, Switch, Empty } from "antd";
import TimelineEvent from "./components/TimelineEvent";
import CalendarEvents from "./components/CalendarEvents";
import ListEvents from "./components/ListEvents";

import CircleSpinner from "core/components/CircleSpinner";

import { isEmpty } from "lodash";

import "./styles.scss";

import { getEvents } from "api/events.api";

function EventLog(props) {
  const [list, setList] = useState([]);
  const [timelineMap, setTimelineMap] = useState({});
  const [loading, setLoading] = useState(false);
  const [isPtTimeZone, setIsPtTimeZone] = useState(false);

  const {
    tests: { test },
  } = props;

  useEffect(() => {
    async function f() {
      setLoading(true);
      const {
        tests: { test },
      } = props;

      const timelineMap = await getEvents(test._id);
      setTimelineMap(timelineMap);
      setLoading(false);
    }
    f();
  }, []);

  const handleDateClick = (dateObj) => {
    if (dateObj) return setList(dateObj.data);
    setList([]);
  };

  const renderTimeline = () => {
    if (isEmpty(timelineMap)) {
      return <Empty description="No events recorded for this test" />;
    }
    return Object.keys(timelineMap).map((d, i) => {
      return (
        <Timeline key={i}>
          <div className="timeline-date-row">
            <span className="timeline-date">{d}</span>
          </div>
          {timelineMap[d].map((evt, i) => {
            return (
              <TimelineEvent
                key={i}
                ti={evt}
                test={test}
                isPtTimeZone={isPtTimeZone}
              />
            );
          })}
        </Timeline>
      );
    });
  };

  if (loading) {
    return (
      <Row gutter={[32, 32]}>
        <Col span={24} className="mt-5 center">
          <CircleSpinner />
        </Col>
      </Row>
    );
  }

  return (
    <div id="event-log" className="page-event-log">
      <Row gutter={[32, 0]}>
        <Col span={12}>
          <div className="timeline-container">
            <Card
              title="Event Log"
              extra={
                <div className="d-flex">
                  <div className="mr-3">Time zone</div>
                  <div style={{ width: 64 }}>
                    <Switch
                      onClick={() => setIsPtTimeZone(!isPtTimeZone)}
                      checkedChildren="HCP"
                      unCheckedChildren="Pt"
                      defaultChecked
                    />
                  </div>
                </div>
              }
            >
              {renderTimeline()}
            </Card>
          </div>
        </Col>
        <Col span={12}>
          <CalendarEvents
            timelineMap={timelineMap}
            list={list}
            handleDateClick={handleDateClick}
          />
          <ListEvents list={list} />
        </Col>
      </Row>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    system: state.system,
    tests: state.tests,
  };
}

export default connect(mapStateToProps)(EventLog);
