import React from "react";
import { Calendar } from "antd";

interface Props {
  timelineMap: any;
  list: any;
  handleDateClick(e): any;
}

class CalendarEvents extends React.Component<Props> {
  getEventDates = (value) => {
    let eventData;
    for (let i in this.props.timelineMap) {
      let d = `${new Date(i).getDate()}-${new Date(i).getMonth()}-${new Date(
        i
      ).getFullYear()}`;
      let date = `${value.date()}-${value.month()}-${value.year()}`;
      if (d === date) {
        eventData = [{ key: date, data: this.props.timelineMap[i] }];
      }
    }
    return eventData || [];
  };

  dateCellRender = (value) => {
    const eventDates = this.getEventDates(value);
    if (
      eventDates.length > 0 &&
      eventDates[0].key === `${value.date()}-${value.month()}-${value.year()}`
    ) {
      return (
        <div
          onClick={() => this.props.handleDateClick(eventDates[0])}
          className="ant-picker-cell-inner ant-picker-calendar-date"
        >
          <div className="ant-picker-calendar-date-value">
            <b>{value.date()}</b>
          </div>
          <div className="ant-picker-calendar-date-content"></div>
        </div>
      );
    }
    return (
      <div
        onClick={() => this.props.handleDateClick(null)}
        className="ant-picker-cell-inner ant-picker-calendar-date"
      >
        <div className="ant-picker-calendar-date-value">{value.date()}</div>
        <div className="ant-picker-calendar-date-content"></div>
      </div>
    );
  };

  render() {
    return (
      <div className="calendar-container">
        <div>
          <Calendar
            fullscreen={false}
            mode={"month"}
            dateFullCellRender={this.dateCellRender}
          />
        </div>
      </div>
    );
  }
}

export default CalendarEvents;
