import React, { useState } from "react";
import { Row, Col, Card, Image } from "antd";
import { EditOutlined } from "@ant-design/icons";
import "../styles.scss";
import moment from "moment";
import AhiBar from "svgs/ahi_bar.svg";

const NightTab = (props) => {
  const {
    test,
    testResults: { variables },
    night,
  } = props;

  const bindingVariables = variables.data[2];
  const [isUsRegion] = useState(() => (bindingVariables.region ? true : false));

  const handleReviewRawDataClicked = () => {
    const win = window.open(
      `${HCP_DASHBOARD_URL}/dashboard/charting?unit=${test.unit._id}&test=${test._id}&night=${night}`,
      "_blank"
    );
    win.focus();
  };

  const bindVariableToNight = (variable) => {
    return bindingVariables[variable + night];
  };

  const bindImageToNight = (data) => {
    const imgObj = data.find((nightObj) => {
      return nightObj.night === night;
    });

    return Object.keys(imgObj).map((o, i) => {
      if (o !== "night") {
        if (o === "poincare") {
          return isUsRegion ? null : (
            <div key={i}>
              <Image height={250} src={imgObj[o]} />
            </div>
          );
        }
        return (
          <div key={i}>
            <Image height={150} width={"100%"} src={imgObj[o]} />
          </div>
        );
      }
    });
  };

  return (
    <div className="page-night">
      <Row gutter={[32, 32]}>
        <Col span={8} className="two-columns-card">
          <Card title="Recording Info">
            <Row gutter={[16, 16]}>
              <Col span={12} className="text-1">
                <div>
                  <span>Recording start</span>
                </div>
                <div>
                  <span>Recording end</span>
                </div>
                <div>
                  <span>Rejected recording</span>
                </div>
              </Col>
              <Col span={12} className="text-2">
                <div>
                  <span>
                    {moment(
                      bindVariableToNight("Recording_Start_Night_")
                    ).format("YYYY/MM/DD \xa0 hh:mm")}
                  </span>
                </div>
                <div>
                  <span>
                    {moment(bindVariableToNight("Recording_End_Night_")).format(
                      "YYYY/MM/DD \xa0 hh:mm"
                    )}
                  </span>
                </div>
                <div>
                  <span>
                    {bindVariableToNight("Rejected_Recording_Night_")}%
                  </span>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={8} className="two-columns-card">
          <Card title="Sleep Time" style={{ minHeight: "184px" }}>
            <Row gutter={[16, 16]}>
              <Col span={16}>
                <div>
                  <span>TST</span>
                  <span>{bindVariableToNight("Total_Sleep_Time_Night_")}</span>
                </div>
                {isUsRegion && (
                  <>
                    <div>
                      <span>REM Time</span>
                      <span>{bindVariableToNight("REM_Time_Night_")}</span>
                    </div>
                    <div>
                      <span>SE</span>
                      <span>{bindVariableToNight("SE_Night_")}</span>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={8} className="two-columns-card">
          <Card title="Pulse Rate">
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div>
                  <span>Mean PR</span>
                  <span>{bindVariableToNight("Avg_PR_Night_")}</span>
                </div>
                <div>
                  <span>Max PR</span>
                  <span>{bindVariableToNight("Max_PR_Night_")}</span>
                </div>
                <div>
                  <span>Min PR</span>
                  <span>{bindVariableToNight("Min_PR_Night_")}</span>
                </div>
              </Col>
              <Col offset={1} span={11}>
                <div>
                  <span>{"Pulse Rate > 100"}</span>
                  <span>
                    {bindVariableToNight("Beats_greater_100_Night_")}%
                  </span>
                </div>
                <div>
                  <span>{"Pulse Rate > 40"}</span>
                  <span>{bindVariableToNight("Beats_less_40_Night_")}</span>
                </div>
                {isUsRegion && (
                  <div>
                    <span>Ectopic beats</span>
                    <span>{bindVariableToNight("Ectopic_Night_")}</span>
                  </div>
                )}
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={8} className="two-columns-card">
          <Card title="SpO2">
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div>
                  <span>Minimum SpO2</span>
                  <span>{bindVariableToNight("Min_SPO2_Night_")}%</span>
                </div>
                <div>
                  <span>Maximum SpO2</span>
                  <span>{bindVariableToNight("Max_SPO2_Night_")}%</span>
                </div>
                {isUsRegion && (
                  <div>
                    <span>Avg SpO2</span>
                    <span>{bindVariableToNight("Avg_SPO2_Night_")}%</span>
                  </div>
                )}
              </Col>
              <Col offset={1} span={11}>
                <div>
                  <span>{"ODI ≥ 3"}</span>
                  <span>
                    {bindVariableToNight("ODI_3_Percent_Night_")} events/h
                  </span>
                </div>
                <div>
                  <span>{"ODI ≥ 4"}</span>
                  <span>
                    {bindVariableToNight("ODI_4_Percent_Night_")} events/h
                  </span>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={16} className="two-columns-card">
          <Card title="Apnea Hypopnea Index (AHI) estimate">
            <Row gutter={[16, 16]}>
              {isUsRegion ? (
                <>
                  <Image src={AhiBar} />
                  <div>
                    <span>pAHI</span>
                    <span>{bindVariableToNight("AHI_Night_")} events/h</span>
                  </div>
                </>
              ) : (
                <>
                  <Col span={10}>
                    <Image src={AhiBar} width={300} />
                  </Col>
                  <Col offset={1} span={9}>
                    <div>
                      <div>
                        <span>AHI</span>
                        <span>
                          {bindVariableToNight("AHI_Night_")} events/h
                        </span>
                      </div>
                      <div>
                        <span>AHI Category (AASM)</span>
                        <span>
                          {bindVariableToNight("AHI_Category_Night_")}
                        </span>
                      </div>
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </Card>
        </Col>
      </Row>
      {bindingVariables.nightlyImages ? (
        <Row gutter={[32, 32]} className="mt-5">
          <Col span={24}>
            <Card
              title={`Signal overview night ${night}`}
              extra={
                <div
                  className="green cursor-pointer"
                  onClick={handleReviewRawDataClicked}
                >
                  <EditOutlined color="green" />
                  <span> Review raw data</span>
                </div>
              }
            >
              {bindImageToNight(bindingVariables.nightlyImages)}
            </Card>
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

export default NightTab;
