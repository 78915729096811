export const uninitialized = "uninitialized";
export const initialized = "initialized";
export const success = "success";
export const error = "error";

export const CATEGORIES_LIST_REQUEST = "CATEGORIES_LIST_REQUEST";
export const CATEGORIES_LIST_RESPONSE = "CATEGORIES_LIST_RESPONSE";

export const TEMPLATES_LIST_REQUEST = "TEMPLATES_LIST_REQUEST";
export const TEMPLATES_LIST_RESPONSE = "TEMPLATES_LIST_RESPONSE";

export const TEMPLATE_REQUEST = "TEMPLATE_REQUEST";
export const TEMPLATE_RESPONSE = "TEMPLATE_RESPONSE";

export const EDIT_TEMPLATE_REQUEST = "EDIT_TEMPLATE_REQUEST";
export const EDIT_TEMPLATE_RESPONSE = "EDIT_TEMPLATE_RESPONSE";

export const DELETE_TEMPLATE_REQUEST = "DELETE_TEMPLATE_REQUEST";
export const DELETE_TEMPLATE_RESPONSE = "DELETE_TEMPLATE_RESPONSE";

export const CREATE_TEMPLATE_REQUEST = "CREATE_TEMPLATE_REQUEST";
export const CREATE_TEMPLATE_RESPONSE = "CREATE_TEMPLATE_RESPONSE";

export const CLEAR_TEMPLATE = "CLEAR_TEMPLATE";

export const MAKE_TEMPLATE_FROM_SHELL_REQUEST =
  "MAKE_TEMPLATE_FROM_SHELL_REQUEST";
export const MAKE_TEMPLATE_FROM_SHELL_RESPONSE =
  "MAKE_TEMPLATE_FROM_SHELL_RESPONSE";

export const SNIPPETS_LIST_REQUEST = "SNIPPETS_LIST_REQUEST";
export const SNIPPETS_LIST_RESPONSE = "SNIPPETS_LIST_RESPONSE";

export const SNIPPET_REQUEST = "SNIPPET_REQUEST";
export const SNIPPET_RESPONSE = "SNIPPET_RESPONSE";

export const EDIT_SNIPPET_REQUEST = "EDIT_SNIPPET_REQUEST";
export const EDIT_SNIPPET_RESPONSE = "EDIT_SNIPPET_RESPONSE";

export const DELETE_SNIPPET_REQUEST = "DELETE_SNIPPET_REQUEST";
export const DELETE_SNIPPET_RESPONSE = "DELETE_SNIPPET_RESPONSE";

export const CREATE_SNIPPET_REQUEST = "CREATE_SNIPPET_REQUEST";
export const CREATE_SNIPPET_RESPONSE = "CREATE_SNIPPET_RESPONSE";

export const VARIABLES_LIST_REQUEST = "VARIABLES_LIST_REQUEST";
export const VARIABLES_LIST_RESPONSE = "VARIABLES_LIST_RESPONSE";

export const SIGNOFF_REQUEST = "SIGNOFF_REQUEST";
export const SIGNOFF_RESPONSE = "SIGNOFF_RESPONSE";

export const PREVIEW_REQUEST = "PREVIEW_REQUEST";
export const PREVIEW_RESPONSE = "PREVIEW_RESPONSE";

export const DRAFT_INTERPRETATION_REQUEST = "DRAFT_INTERPRETATION_REQUEST";
export const DRAFT_INTERPRETATION_RESPONSE = "DRAFT_INTERPRETATION_RESPONSE";

export const CLEAR_DRAFT = "CLEAR_DRAFT";
