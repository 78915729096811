import { composeSurveyQA } from "containers/Surveys/utils";
import { apiInstance } from "./axios";

export function getSurveys(test_id: string) {
  return (
    apiInstance
      // .get(`surveys/response/test/${test_id}`)
      .get(`surveys/response/test/6018eaa20185b3002bc1d752`)
      .then((res) => {
        // console.log(res.data.responses);
        // return composeSurveyQA(res.data.responses);

        let b = {
          responses: [
            {
              _acl: {
                role: [
                  {
                    roles: [
                      "ADMIN_5ff73f2f26fa4a008f11a575",
                      "PHYSICIAN_5ff73f2f26fa4a008f11a575",
                    ],
                    _id: "61651937c5573d001daefe97",
                    service: "HCP",
                  },
                ],
                user: [],
              },
              _id: "61651937c5573d001daefe96",
              survey: {
                _acl: {
                  role: [],
                  user: [],
                },
                type: "CLINICAL",
                units: [],
                _id: "5d5bf80cf352ce001b955489",
                name: "Sleep diary/soft note - v1",
                survey: {
                  pages: [
                    {
                      name: "page1",
                      elements: [
                        {
                          type: "text",
                          name: "Subjective SOL",
                          title:
                            "How long did it take you to fall asleep once you started the recording?",
                          description: "(minutes)",
                          defaultValue: 0,
                          isRequired: true,
                          inputType: "number",
                          maxLength: 60,
                        },
                        {
                          type: "text",
                          name: "Subjective awakenings",
                          title:
                            "How many times and at what times did you wake up during the night?",
                          isRequired: true,
                        },
                        {
                          type: "comment",
                          name: "Affecting factors",
                          title:
                            "Were there any factors affecting your sleep last night?",
                          description:
                            "e.g. medication, alcohol consumption, bed partner, pets, room conditions...",
                          isRequired: true,
                        },
                        {
                          type: "radiogroup",
                          name: "Representativeness",
                          title:
                            "How representative was last night of your normal sleep pattern?",
                          isRequired: true,
                          choices: ["Not", "Somewhat", "Highly"],
                        },
                      ],
                    },
                  ],
                },
                modified_at: "2019-08-20T13:39:24.640Z",
                created_at: "2019-08-20T13:39:24.640Z",
                __v: 0,
              },
              response: {
                "Subjective SOL": 60,
                "Subjective awakenings": "2.  8pm",
                "Affecting factors": "Wife coming to bed",
                Representativeness: "Highly",
              },
              unit: "5ff73f2f26fa4a008f11a575",
              test: "6164374b772f980028cc4ef2",
              recording: "6164bfdaf27f7f001e6c5b27",
              patient: "6164374b772f980028cc4eeb",
              modified_at: "2021-10-12T05:12:23.329Z",
              created_at: "2021-10-12T05:12:23.329Z",
              __v: 0,
            },
            {
              _acl: {
                role: [
                  {
                    roles: [
                      "ADMIN_5ff73f2f26fa4a008f11a575",
                      "PHYSICIAN_5ff73f2f26fa4a008f11a575",
                    ],
                    _id: "6166674a9c5601001f4e08ac",
                    service: "HCP",
                  },
                ],
                user: [],
              },
              _id: "6166674a9c5601001f4e08ab",
              survey: {
                _acl: {
                  role: [],
                  user: [],
                },
                type: "CLINICAL",
                units: [],
                _id: "5d5bf80cf352ce001b955489",
                name: "Sleep diary/soft note - v1",
                survey: {
                  pages: [
                    {
                      name: "page1",
                      elements: [
                        {
                          type: "text",
                          name: "Subjective SOL",
                          title:
                            "How long did it take you to fall asleep once you started the recording?",
                          description: "(minutes)",
                          defaultValue: 0,
                          isRequired: true,
                          inputType: "number",
                          maxLength: 60,
                        },
                        {
                          type: "text",
                          name: "Subjective awakenings",
                          title:
                            "How many times and at what times did you wake up during the night?",
                          isRequired: true,
                        },
                        {
                          type: "comment",
                          name: "Affecting factors",
                          title:
                            "Were there any factors affecting your sleep last night?",
                          description:
                            "e.g. medication, alcohol consumption, bed partner, pets, room conditions...",
                          isRequired: true,
                        },
                        {
                          type: "radiogroup",
                          name: "Representativeness",
                          title:
                            "How representative was last night of your normal sleep pattern?",
                          isRequired: true,
                          choices: ["Not", "Somewhat", "Highly"],
                        },
                      ],
                    },
                  ],
                },
                modified_at: "2019-08-20T13:39:24.640Z",
                created_at: "2019-08-20T13:39:24.640Z",
                __v: 0,
              },
              response: {
                "Subjective awakenings": "1 time 8pm",
                "Affecting factors": "Wife came to bed  went to bathroom ",
                Representativeness: "Highly",
                "Subjective SOL": 60,
              },
              unit: "5ff73f2f26fa4a008f11a575",
              test: "6164374b772f980028cc4ef2",
              recording: "61660ceaeefbed001d9837ef",
              patient: "6164374b772f980028cc4eeb",
              modified_at: "2021-10-13T04:57:46.247Z",
              created_at: "2021-10-13T04:57:46.247Z",
              __v: 0,
            },
            {
              _acl: {
                role: [
                  {
                    roles: [
                      "ADMIN_5ff73f2f26fa4a008f11a575",
                      "PHYSICIAN_5ff73f2f26fa4a008f11a575",
                    ],
                    _id: "6167bbc1879b70002b3fa05a",
                    service: "HCP",
                  },
                ],
                user: [],
              },
              _id: "6167bbc1879b70002b3fa059",
              survey: {
                _acl: {
                  role: [],
                  user: [],
                },
                type: "CLINICAL",
                units: [],
                _id: "5d5bf80cf352ce001b955489",
                name: "Sleep diary/soft note - v1",
                survey: {
                  pages: [
                    {
                      name: "page1",
                      elements: [
                        {
                          type: "text",
                          name: "Subjective SOL",
                          title:
                            "How long did it take you to fall asleep once you started the recording?",
                          description: "(minutes)",
                          defaultValue: 0,
                          isRequired: true,
                          inputType: "number",
                          maxLength: 60,
                        },
                        {
                          type: "text",
                          name: "Subjective awakenings",
                          title:
                            "How many times and at what times did you wake up during the night?",
                          isRequired: true,
                        },
                        {
                          type: "comment",
                          name: "Affecting factors",
                          title:
                            "Were there any factors affecting your sleep last night?",
                          description:
                            "e.g. medication, alcohol consumption, bed partner, pets, room conditions...",
                          isRequired: true,
                        },
                        {
                          type: "radiogroup",
                          name: "Representativeness",
                          title:
                            "How representative was last night of your normal sleep pattern?",
                          isRequired: true,
                          choices: ["Not", "Somewhat", "Highly"],
                        },
                      ],
                    },
                  ],
                },
                modified_at: "2019-08-20T13:39:24.640Z",
                created_at: "2019-08-20T13:39:24.640Z",
                __v: 0,
              },
              response: {
                "Subjective SOL": 45,
                "Subjective awakenings": "2. 7:30.  Pm 10:00 pm",
                "Affecting factors": "Wife came to bed  bathroom ",
                Representativeness: "Highly",
              },
              unit: "5ff73f2f26fa4a008f11a575",
              test: "6164374b772f980028cc4ef2",
              recording: "616766ed86e6d2001ed85aa8",
              patient: "6164374b772f980028cc4eeb",
              modified_at: "2021-10-14T05:10:25.581Z",
              created_at: "2021-10-14T05:10:25.581Z",
              __v: 0,
            },
            {
              _acl: {
                role: [
                  {
                    roles: [
                      "ADMIN_601567fb78b95c004bf0cd64",
                      "PHYSICIAN_601567fb78b95c004bf0cd64",
                    ],
                    _id: "601a390252222600287c54ca",
                    service: "SISU",
                  },
                ],
                user: [],
              },
              _id: "601a390252222600287c54c9",
              survey: {
                _acl: {
                  role: [],
                  user: [],
                },
                type: "CLINICAL",
                units: [],
                _id: "60113e2558330400e5f54f3f",
                name: "Pittsburgh Sleep Quality Index",
                description:
                  "The Pittsburgh Sleep Quality Index (PSQI) is a clinical survey for the sleep physician to assess your sleep quality.",
                disclaimer:
                  "© 1989, University of Pittsburgh.\\nAll rights reserved.",
                info: {
                  title: "Instructions",
                  content:
                    "The following questions relate to your usual sleep habits during the past month only.\\nYour answers should indicate the most accurate reply for the majority of days and nights in the past month. Please answer all questions",
                },
                survey: {
                  pages: [
                    {
                      name: "page1",
                      elements: [
                        {
                          type: "text",
                          name: "question1",
                          title:
                            "During the past month, what time have you usually gone to bed at night? ",
                          isRequired: true,
                          inputType: "time",
                        },
                        {
                          type: "text",
                          name: "question2",
                          title:
                            "During the past month, how long (in minutes) has it usually taken you to fall asleep each night?",
                          isRequired: true,
                          inputType: "number",
                          min: "0",
                          max: "600",
                        },
                        {
                          type: "text",
                          name: "question3",
                          title:
                            "During the past month, what time have you usually gotten up in the morning?",
                          isRequired: true,
                          inputType: "time",
                        },
                        {
                          type: "text",
                          name: "question4",
                          title:
                            "During the past month, how many hours of actual sleep did you get at night? (This may be different than the number of hours you spent in bed.)",
                          inputType: "number",
                          min: "0",
                          max: "900",
                        },
                        {
                          type: "panel",
                          name: "panel1",
                          elements: [
                            {
                              type: "html",
                              name: "question5",
                              html: "<b> <i> For each of the remaining questions, check the one best response. Please answer <u> all </u> questions. </i> </b> \n<b> <p> 5. During the <u>past month, </u> how often have you had trouble sleeping because you... </p></b>\n",
                            },
                            {
                              type: "dropdown",
                              name: "question5a",
                              title: "a. Cannot get to sleep within 30 minutes",
                              hideNumber: true,
                              isRequired: true,
                              choices: [
                                {
                                  value: "0",
                                  text: "Not during the past month",
                                },
                                {
                                  value: "1",
                                  text: "Less than once a week",
                                },
                                {
                                  value: "2",
                                  text: "Once or twice a week",
                                },
                                {
                                  value: "3",
                                  text: "Three or more times a week",
                                },
                              ],
                            },
                            {
                              type: "dropdown",
                              name: "question5b",
                              title:
                                "b. Wake up in the middle of the night or early  morning ",
                              hideNumber: true,
                              isRequired: true,
                              choices: [
                                {
                                  value: "0",
                                  text: "Not during the past month",
                                },
                                {
                                  value: "1",
                                  text: "Less than once a week",
                                },
                                {
                                  value: "2",
                                  text: "Once or twice a week",
                                },
                                {
                                  value: "3",
                                  text: "Three or more times a week",
                                },
                              ],
                            },
                            {
                              type: "dropdown",
                              name: "question5c",
                              title: "c. Have to get up to use the bathroom ",
                              hideNumber: true,
                              isRequired: true,
                              choices: [
                                {
                                  value: "0",
                                  text: "Not during the past month",
                                },
                                {
                                  value: "1",
                                  text: "Less than once a week",
                                },
                                {
                                  value: "2",
                                  text: "Once or twice a week",
                                },
                                {
                                  value: "3",
                                  text: "Three or more times a week",
                                },
                              ],
                            },
                            {
                              type: "dropdown",
                              name: "question5d",
                              title: "d. Cannot breathe comfortably ",
                              hideNumber: true,
                              isRequired: true,
                              choices: [
                                {
                                  value: "0",
                                  text: "Not during the past month",
                                },
                                {
                                  value: "1",
                                  text: "Less than once a week",
                                },
                                {
                                  value: "2",
                                  text: "Once or twice a week",
                                },
                                {
                                  value: "3",
                                  text: "Three or more times a week",
                                },
                              ],
                            },
                            {
                              type: "dropdown",
                              name: "question5e",
                              title: "e. Cough or snore loudly ",
                              hideNumber: true,
                              isRequired: true,
                              choices: [
                                {
                                  value: "0",
                                  text: "Not during the past month",
                                },
                                {
                                  value: "1",
                                  text: "Less than once a week",
                                },
                                {
                                  value: "2",
                                  text: "Once or twice a week",
                                },
                                {
                                  value: "3",
                                  text: "Three or more times a week",
                                },
                              ],
                            },
                            {
                              type: "dropdown",
                              name: "question5f",
                              title: "f. Feel too cold ",
                              hideNumber: true,
                              isRequired: true,
                              choices: [
                                {
                                  value: "0",
                                  text: "Not during the past month",
                                },
                                {
                                  value: "1",
                                  text: "Less than once a week",
                                },
                                {
                                  value: "2",
                                  text: "Once or twice a week",
                                },
                                {
                                  value: "3",
                                  text: "Three or more times a week",
                                },
                              ],
                            },
                            {
                              type: "dropdown",
                              name: "question5g",
                              title: "g. Feel too hot ",
                              hideNumber: true,
                              isRequired: true,
                              choices: [
                                {
                                  value: "0",
                                  text: "Not during the past month",
                                },
                                {
                                  value: "1",
                                  text: "Less than once a week",
                                },
                                {
                                  value: "2",
                                  text: "Once or twice a week",
                                },
                                {
                                  value: "3",
                                  text: "Three or more times a week",
                                },
                              ],
                            },
                            {
                              type: "dropdown",
                              name: "question5h",
                              title: "h. Have bad dreams ",
                              hideNumber: true,
                              isRequired: true,
                              choices: [
                                {
                                  value: "0",
                                  text: "Not during the past month",
                                },
                                {
                                  value: "1",
                                  text: "Less than once a week",
                                },
                                {
                                  value: "2",
                                  text: "Once or twice a week",
                                },
                                {
                                  value: "3",
                                  text: "Three or more times a week",
                                },
                              ],
                            },
                            {
                              type: "dropdown",
                              name: "question5i",
                              title: "i. Have pain ",
                              hideNumber: true,
                              isRequired: true,
                              choices: [
                                {
                                  value: "0",
                                  text: "Not during the past month",
                                },
                                {
                                  value: "1",
                                  text: "Less than once a week",
                                },
                                {
                                  value: "2",
                                  text: "Once or twice a week",
                                },
                                {
                                  value: "3",
                                  text: "Three or more times a week",
                                },
                              ],
                            },
                            {
                              type: "comment",
                              name: "question5j",
                              title: "j. Other reason(s), please describe:",
                              hideNumber: true,
                            },
                          ],
                        },
                        {
                          type: "dropdown",
                          name: "question6",
                          title:
                            "6. During the past month, how would you rate your sleep quality overall?",
                          hideNumber: true,
                          isRequired: true,
                          choices: [
                            {
                              value: "0",
                              text: "Very good",
                            },
                            {
                              value: "1",
                              text: "Fairly good",
                            },
                            {
                              value: "2",
                              text: "Fairly bad",
                            },
                            {
                              value: "3",
                              text: "Very bad",
                            },
                          ],
                        },
                        {
                          type: "dropdown",
                          name: "question7",
                          title:
                            "7. During the past month, how often have you taken medicine to help you sleep (prescribed or “over the counter”)?",
                          hideNumber: true,
                          isRequired: true,
                          choices: [
                            {
                              value: "0",
                              text: "Not during the past month",
                            },
                            {
                              value: "1",
                              text: "Less than once a week",
                            },
                            {
                              value: "2",
                              text: "Once or twice a week",
                            },
                            {
                              value: "3",
                              text: "Three or more times a week",
                            },
                          ],
                        },
                        {
                          type: "dropdown",
                          name: "question8",
                          title:
                            "8. During the past month, how often have you had trouble staying awake while driving, eating meals, or engaging in social activity?",
                          hideNumber: true,
                          isRequired: true,
                          choices: [
                            {
                              value: "0",
                              text: "Not during the past month",
                            },
                            {
                              value: "1",
                              text: "Less than once a week",
                            },
                            {
                              value: "2",
                              text: "Once or twice a week",
                            },
                            {
                              value: "3",
                              text: "Three or more times a week",
                            },
                          ],
                        },
                        {
                          type: "dropdown",
                          name: "question9",
                          title:
                            "9. During the past month, how much of a problem has it been for you to keep up enough enthusiasm to get things done?",
                          hideNumber: true,
                          isRequired: true,
                          choices: [
                            {
                              value: "0",
                              text: "No problem at all",
                            },
                            {
                              value: "1",
                              text: "Only a very slight problem",
                            },
                            {
                              value: "2",
                              text: "Somewhat of a problem",
                            },
                            {
                              value: "3",
                              text: "A very big problem",
                            },
                          ],
                        },
                        {
                          type: "dropdown",
                          name: "question10",
                          title: "10. Do you have a bed partner or room mate?",
                          hideNumber: true,
                          isRequired: true,
                          choices: [
                            {
                              value: "0",
                              text: "No bed partner or room mate",
                            },
                            {
                              value: "1",
                              text: "Partner/room mate in other room",
                            },
                            {
                              value: "2",
                              text: "Partner in same room but not same bed",
                            },
                            {
                              value: "3",
                              text: "Partner in same bed",
                            },
                          ],
                        },
                        {
                          type: "panel",
                          name: "panel2",
                          elements: [
                            {
                              type: "html",
                              name: "question11",
                              html: "<b> If you have a room mate or bed partner, ask him/her how often in the past month you have had...",
                            },
                            {
                              type: "dropdown",
                              name: "question10a",
                              title: "a. Loud snoring",
                              hideNumber: true,
                              choices: [
                                {
                                  value: "0",
                                  text: "Not during the past month",
                                },
                                {
                                  value: "1",
                                  text: "Less than once a week",
                                },
                                {
                                  value: "2",
                                  text: "Once or twice a week",
                                },
                                {
                                  value: "3",
                                  text: "Three or more times a week",
                                },
                              ],
                            },
                            {
                              type: "dropdown",
                              name: "question10b",
                              title:
                                "b. Long pauses between breaths while asleep",
                              hideNumber: true,
                              choices: [
                                {
                                  value: "0",
                                  text: "Not during the past month",
                                },
                                {
                                  value: "1",
                                  text: "Less than once a week",
                                },
                                {
                                  value: "2",
                                  text: "Once or twice a week",
                                },
                                {
                                  value: "3",
                                  text: "Three or more times a week",
                                },
                              ],
                            },
                            {
                              type: "dropdown",
                              name: "question10c",
                              title:
                                "c. Legs twitching or jerking while you sleep",
                              hideNumber: true,
                              choices: [
                                {
                                  value: "0",
                                  text: "Not during the past month",
                                },
                                {
                                  value: "1",
                                  text: "Less than once a week",
                                },
                                {
                                  value: "2",
                                  text: "Once or twice a week",
                                },
                                {
                                  value: "3",
                                  text: "Three or more times a week",
                                },
                              ],
                            },
                            {
                              type: "dropdown",
                              name: "question10d",
                              title:
                                "d. Episodes of disorientation or confusion during sleep",
                              hideNumber: true,
                              choices: [
                                {
                                  value: "0",
                                  text: "Not during the past month",
                                },
                                {
                                  value: "1",
                                  text: "Less than once a week",
                                },
                                {
                                  value: "2",
                                  text: "Once or twice a week",
                                },
                                {
                                  value: "3",
                                  text: "Three or more times a week",
                                },
                              ],
                            },
                            {
                              type: "comment",
                              name: "question10e",
                              title:
                                "e. Other restlessness while you sleep, please describe:",
                              hideNumber: true,
                            },
                          ],
                          visibleIf:
                            "{question10} = 1 or {question10} = 2 or {question10} = 3",
                        },
                      ],
                    },
                  ],
                },
                modified_at: "2020-01-27T18:30:00.000Z",
                created_at: "2020-01-27T18:30:00.000Z",
                __v: 0,
              },
              response: {
                question1: "00:00",
                question2: "12:00",
                question3: "12:00",
                question4: "12:12",
                question18: "2",
                question34: "1",
                question33: "1",
                question32: "1",
                question31: "1",
                question20: "3",
                question27: "0",
                question36: "2",
                question35: "3",
                question5: "2",
                question19: "sleep, what is this unicorn you talk about",
                question8: "2",
                question9: "1",
                question10: "1",
                question11: "0",
                question12: "1",
                question17: "0",
                question15: "0",
                question13: "0",
                question28: "well well well",
              },
              unit: "601567fb78b95c004bf0cd64",
              test: "6018eaa20185b3002bc1d752",
              recording: "5fe46ab90a05e9004eac8b14",
              patient: "6018eaa20185b3002bc1d74e",
              modified_at: "2021-02-03T05:47:46.911Z",
              created_at: "2021-02-03T05:47:46.911Z",
              __v: 0,
            },
            {
              _acl: {
                role: [
                  {
                    roles: [
                      "ADMIN_609d1e353b83f500500d8dab",
                      "PHYSICIAN_609d1e353b83f500500d8dab",
                    ],
                    _id: "6167ea505b93fc001eaf905f",
                    service: "HCP",
                  },
                ],
                user: [],
              },
              _id: "6167ea505b93fc001eaf905e",
              survey: {
                _acl: {
                  user: [
                    {
                      permissions: ["GET", "POST", "DELETE", "PATCH", "PUT"],
                      _id: "5e149be4750a29001ca64f77",
                      id: "5d022a541c9d440000cb6a43",
                    },
                  ],
                  role: [
                    {
                      roles: [],
                      _id: "5e149be4750a29001ca64f78",
                      service: "HCP",
                    },
                  ],
                },
                type: "CLINICAL",
                units: [],
                _id: "5e149be4750a29001ca64f76",
                name: "General - NPS",
                survey: {
                  pages: [
                    {
                      name: "NPS",
                      elements: [
                        {
                          type: "rating",
                          name: "NPS",
                          title: {
                            default:
                              "How likely are you to recommend this sleep test to a relative, friend or colleague, based on your experience thus far?",
                            nl: "Hoe waarschijnijk zou je deze test aanbevelen aan een familielid, vriend of collega, gebaseerd op jouw ervaring tot nu toe?",
                          },
                          isRequired: true,
                          rateMax: 10,
                          description: {
                            default:
                              "( 1 = Not at all likely ... 10 = Extremely likely )",
                            nl: "( 1 = Zeer onwaarschijnlijk ... 10 = Zeer waarschijnlijk )",
                          },
                        },
                        {
                          type: "comment",
                          name: "How did we impress you?",
                          visibleIf: "{NPS} >= 7",
                          title: {
                            default: "How did we impress you?",
                            nl: "Hoe hebben we indruk gemaakt?",
                          },
                          isRequired: true,
                        },
                        {
                          type: "text",
                          name: "Feedback",
                          visibleIf: "{NPS} <= 8",
                          title: {
                            default: "How can we do better?",
                            nl: "Hoe kunnen we beter doen?",
                          },
                          enableIf: "{NPS} <= 9",
                          isRequired: true,
                        },
                      ],
                    },
                  ],
                },
                modified_at: "2020-01-07T14:55:32.279Z",
                created_at: "2020-01-07T14:55:32.279Z",
                __v: 0,
              },
              response: {
                NPS: "3",
                Feedback: "It was recommended by my doctor. ",
              },
              unit: "609d1e353b83f500500d8dab",
              test: "61671ae2bbc279002a964b51",
              recording: null,
              patient: "61671ae2bbc279002a964b4a",
              modified_at: "2021-10-14T08:29:04.513Z",
              created_at: "2021-10-14T08:29:04.513Z",
              __v: 0,
            },
          ],
        };
        const f = composeSurveyQA(b);
        return f;
      })
      .catch((err) => ({
        error: true,
      }))
  );
}
