import { combineReducers } from "redux";
import { SystemState } from "./system/system.types";

import { systemReducer } from "./system/system.reducer";
import { testsReducer } from "./tests/tests.reducer";
import { evaluationReducer } from "./evaluation/evaluation.reducer";
import { documentsReducer } from "./documents/documents.reducer";
import { surveysReducer } from "./surveys/surveys.reducers";
import { notesReducer } from "./notes/notes.reducer";

export interface AppState {
  system: SystemState;
}

export const appReducer = combineReducers({
  system: systemReducer,
  tests: testsReducer,
  evaluation: evaluationReducer,
  documents: documentsReducer,
  surveys: surveysReducer,
  notes: notesReducer,
});

export const rootReducer = (state, action) => {
  return appReducer(state, action);
};
