export class PasswordMismatchError extends Error {
  static NAME = "PasswordMismatchError";

  name = PasswordMismatchError.NAME;
}

export class UnauthorizedError extends Error {
  static NAME = "UnauthorizedError";

  name = UnauthorizedError.NAME;
}

export class OperationTimeoutError extends Error {
  static NAME = "OperationTimeoutError";

  name = OperationTimeoutError.NAME;
}

export class IllegalArgumentException extends Error {
  name = "IllegalArgumentException";
}

export class SessionExpiredError extends Error {
  name = "SessionExpiredError";
}
