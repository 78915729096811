import { uninitialized, initialized, success, error } from "./surveys.types";
import { GET_SURVEY_REQUEST, GET_SURVEY_RESPONSE } from "./surveys.types";

const initialState = {
  surveyList: {
    status: uninitialized,
    error: null,
    data: [],
  },
};

export function surveysReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SURVEY_REQUEST:
      return {
        ...state,
        surveyList: {
          ...state.surveyList,
          status: initialized,
        },
      };
    case GET_SURVEY_RESPONSE:
      if (action.payload.error) {
        return {
          ...state,
          surveyList: {
            ...state.surveyList,
            status: error,
            error: action.payload.error,
          },
        };
      }
      return {
        ...state,
        surveyList: {
          ...state.surveyList,
          status: success,
          error: null,
          data: action.payload.surveys,
        },
      };

    default:
      return state;
  }
}
