import * as events from "./events.api";
import * as tests from "./tests.api";
import * as user from "./user.api";
import * as evaluation from "./evaluation.api";
import * as documents from "./documents.api";
import * as surveys from "./surveys.api";
import * as notes from "./notes.api";

export const api = {
  events,
  tests,
  user,
  evaluation,
  documents,
  surveys,
  notes,
};
