import { uninitialized, initialized, success, error } from "./notes.types";

import { UPDATE_NOTES_REQUEST, UPDATE_NOTES_RESPONSE } from "./notes.types";

const initialState = {
  notes: {
    status: uninitialized,
    error: null,
  },
};

export function notesReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_NOTES_REQUEST:
      return {
        ...state,
        notes: {
          ...state.notes,
          status: initialized,
        },
      };
    case UPDATE_NOTES_RESPONSE:
      if (action.payload.error) {
        return {
          ...state,
          notes: {
            ...state.notes,
            status: error,
            error: action.payload.error,
          },
        };
      }
      return {
        ...state,
        notes: {
          ...state.notes,
          status: success,
          error: null,
        },
      };

    default:
      return state;
  }
}
