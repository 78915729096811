import {
  TEST_REQUEST,
  TEST_RESPONSE,
  UPDATE_TEST_RESPONSE,
} from "./tests.types";

const initialState = {
  fetchingList: false,
  fetchingGet: false,
  fetchingProcessing: false,
  test: null,
};

export function testsReducer(state = initialState, action) {
  switch (action.type) {
    case TEST_REQUEST:
      return {
        ...state,
        fetchingList: true,
      };
    case TEST_RESPONSE:
      if (action.payload.error) {
        return {
          ...state,
          error: action.payload.error,
          fetchingList: false,
        };
      }
      return {
        ...state,
        error: null,
        test: action.payload.test,
        fetchingList: false,
      };

    case UPDATE_TEST_RESPONSE:
      if (action.payload.error) {
        return {
          ...state,
          error: action.payload.error,
        };
      }
      return {
        ...state,
        error: null,
        test: action.payload.test,
      };

    default:
      return state;
  }
}
