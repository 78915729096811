import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Card, Button, Input, notification } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

import { updateNotesAction } from "store/notes/notes.actions";

import "./styles.scss";

const { TextArea } = Input;

const Notes = (props) => {
  // const { notes } = props.notes;
  const { test } = props.tests;
  const [text, setText] = useState(() => {
    return test.notes || "";
  });

  const handleSaveClicked = () => {
    const { test } = props.tests;
    props.updateNotesAction(test, text);

    notification.success({
      message: "Notes updated successful",
      placement: "topRight",
      icon: <CheckCircleOutlined style={{ color: "#83c8bc" }} />,
    });
  };

  const onChange = (e) => {
    setText(e.target.value);
  };

  return (
    <div className="page-notes">
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <div>
            <Card title="Notes">
              <div>
                <TextArea
                  value={text}
                  placeholder="Type your notes here..."
                  showCount
                  maxLength={250}
                  onChange={onChange}
                />
              </div>
            </Card>
          </div>
        </Col>
        <div>
          <Button
            style={{ marginLeft: "16px" }}
            type="primary"
            onClick={handleSaveClicked}
          >
            Save
          </Button>
        </div>
      </Row>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    tests: state.tests,
    notes: state.notes,
  };
}

export default connect(mapStateToProps, {
  updateNotesAction,
})(Notes);
