export const eventsObj = [
  {
    event: "test_created",
    timestamp: "2021-07-01T08:00:00.000",
    meta: {},
  },
  {
    event: "device_shipped_to_patient",
    timestamp: "2021-07-01T00:00:00.000",
    meta: {},
  },
  {
    event: "tracking_code",
    timestamp: "2021-07-01T00:00:00.000",
    meta: {},
  },
  {
    event: "patient_opened_activation_email",
    timestamp: "2021-07-01T18:00:00.000",
    meta: {},
  },
  {
    event: "patient_downloaded_the_app",
    timestamp: "2021-07-01T18:00:00.000",
    meta: {},
  },
  {
    event: "device_delivered_at_patient",
    timestamp: "2021-07-02T16:00:00.000",
    meta: {},
  },
  {
    event: "patient_activated_test",
    timestamp: "2021-07-02T22:00:00.000",
    meta: {},
  },
  {
    event: "patient_device_type",
    timestamp: "2021-07-02T23:00:00.000",
    meta: {},
  },
  {
    event: "patient_switched_to_different_electronic_device",
    timestamp: "2021-07-02T23:00:00.000",
    meta: {},
  },
  {
    event: "patient_consented_to_forms",
    timestamp: "2021-07-02T23:00:00.000",
    meta: {},
  },
  {
    event: "patient_completed_or_verified_profile_information",
    timestamp: "2021-07-02T23:00:00.000",
    meta: {},
  },
  {
    event: "patient_completed_in_take_survey_name_of_survey",
    timestamp: "2021-07-02T05:10:53.832Z",
    meta: {},
  },
  {
    event: "patient_started_night",
    timestamp: "2021-07-02T23:00:00.000",
    meta: {},
  },
  {
    event: "temperature_inside_and_outside",
    timestamp: "2021-07-02T23:00:00.000",
    meta: {},
  },
  {
    event: "patient_uses_phone",
    timestamp: "2021-07-02T23:00:00.000",
    meta: {},
  },
  {
    event: "sensor_out_of_range",
    timestamp: "2021-07-02T23:00:00.000",
    meta: {},
  },
  {
    event: "night_recording_successful",
    timestamp: "2021-07-03T07:00:00.000",
    meta: {},
  },
  {
    event: "processing_data_night",
    timestamp: "2021-07-03T05:10:53.832Z",
    meta: {},
  },
  {
    event: "data_night_processed",
    timestamp: "2021-07-03T05:10:53.832Z",
    meta: {},
  },
  {
    event: "data_under_review_by_ectosense",
    timestamp: "2021-07-03T05:10:53.832Z",
    meta: {},
  },
  {
    event: "patient_completed_morning_survey",
    timestamp: "2021-07-03T05:10:53.832Z",
    meta: {},
  },
  {
    event: "night_recording_failed",
    timestamp: "2021-07-03T05:10:53.832Z",
    meta: {},
  },
  {
    event: "reason_why_failed",
    timestamp: "2021-07-03T05:10:53.832Z",
    meta: {},
  },
  {
    event: "patient_visited_instruction_to_improve_next_recording",
    timestamp: "2021-07-03T05:10:53.832Z",
    meta: {},
  },
];

export const primaryEvents = {
  test_created: 1,
  device_shipped_to_patient: 1,
  device_delivered_at_patient: 1,
  patient_return_shipped_device: 1,
  device_received_in_warehouse: 1,
  patient_activated_test: 1,
  patient_failed_to_connect_over_bluetooth: 1,
  patient_updated_firmware_of_sensor_device: 1,
  patient_submitted_a_support_ticket_via_app: 1,
  patient_started_night: 1,
  night_recording_successful: 1,
  night_recording_uploaded: 1,
  night_recording_failed: 1,
  testing_completed: 1,
  test_session_data_or_instructions_altered: 1,
  activation_email_resend: 1,
  note_added: 1,
  data_viewed: 1,
  data_annotations_adjusted_and_report_regenerated: 1,
  study_interpretation_completed: 1,
};

export const secondaryEvents = {
  tracking_code: 1,
  patient_opened_activation_email: 1,
  patient_downloaded_the_app: 1,
  patient_device_type: 1,
  patient_switched_to_different_electronic_device: 1,
  patient_consented_to_forms: 1,
  patient_completed_or_verified_profile_information: 1,
  patient_completed_in_take_survey_name_of_survey: 1,
  temperature_inside_and_outside: 1,
  patient_uses_phone: 1,
  sensor_out_of_range: 1,
  processing_data_night: 1,
  data_night_processed: 1,
  data_under_review_by_ectosense: 1,
  patient_completed_morning_survey: 1,
  reason_why_failed: 1,
  patient_visited_instruction_to_improve_next_recording: 1,
  test_extended: 1,
  patient_completed_experience_survey: 1,
};

const green = "#83c8bc";
const orange = "#ffc53d";
const blue = "#2f54eb";

export const eventValueMap = {
  test_created: {
    text: (meta) => {
      if (meta && meta.first_name !== "" && meta.last_name !== "") {
        return `Test Created by ${meta.first_name} ${meta.last_name}`;
      }
      return "Test Created";
    },
    instigator: "",
    dotColor: blue,
    dotBorder: "",
    borderColor: "blue",
  },
  patient_activated_test: {
    text: () => {
      return "Patient activated test";
    },
    instigator: "",
    dotColor: orange,
    dotBorder: "",
    borderColor: "orange",
  },
  patient_started_night: {
    text: (meta) => {
      return `Patient started night ${meta.night}`;
    },
    instigator: "",
    dotColor: orange,
    dotBorder: "",
    borderColor: "orange",
  },
  night_recording_successful: {
    text: (meta) => {
      return `Night ${meta.night} recording successful`;
    },
    instigator: "",
    dotColor: green,
    dotBorder: "",
    borderColor: "green",
  },
  night_recording_uploaded: {
    text: (meta) => {
      return `Night ${meta.night} recording uploaded`;
    },
    instigator: "",
    dotColor: green,
    dotBorder: "",
    borderColor: "green",
  },
  night_recording_failed: {
    text: (meta) => {
      return `Night ${meta.night} recording failed`;
    },
    instigator: "",
    dotColor: green,
    dotBorder: "",
    borderColor: "green",
  },
  testing_completed: {
    text: () => {
      return "Testing completed";
    },
    instigator: "",
    dotColor: green,
    dotBorder: "",
    borderColor: "green",
  },
  patient_failed_to_connect_over_bluetooth: {
    text: () => {
      return "Patient failed to connect over bluetooth";
    },
    instigator: "",
    dotColor: orange,
    dotBorder: "",
    borderColor: "orange",
  },
  patient_updated_firmware_of_sensor_device: {
    text: () => {
      return "Patient updated firmware of sensor device";
    },
    instigator: "",
    dotColor: orange,
    dotBorder: "",
    borderColor: "orange",
  },
  patient_submitted_a_support_ticket_via_app: {
    text: () => {
      return "Patient submitted a support ticket via app";
    },
    instigator: "",
    dotColor: orange,
    dotBorder: "",
    borderColor: "orange",
  },
  device_shipped_to_patient: {
    text: (meta) => {
      return `Device shipped to patient: ${meta.code}`;
    },
    instigator: "",
    dotColor: green,
    dotBorder: "",
    borderColor: "green",
  },
  device_delivered_at_patient: {
    text: () => {
      return "Device delivered at patient";
    },
    instigator: "",
    dotColor: green,
    dotBorder: "",
    borderColor: "green",
  },
  patient_return_shipped_device: {
    text: () => {
      return "Patient return shipped device";
    },
    instigator: "",
    dotColor: orange,
    dotBorder: "",
    borderColor: "orange",
  },
  device_received_in_warehouse: {
    text: () => {
      return "Device received in warehouse";
    },
    instigator: "",
    dotColor: green,
    dotBorder: "",
    borderColor: "green",
  },
  test_session_data_or_instructions_altered: {
    text: () => {
      return "Test session data or instructions altered";
    },
    instigator: "",
    dotColor: blue,
    dotBorder: "",
    borderColor: "blue",
  },
  activation_email_resend: {
    text: () => {
      return "Activation email resend";
    },
    instigator: "",
    dotColor: blue,
    dotBorder: "",
    borderColor: "blue",
  },
  note_added: {
    text: () => {
      return "Note added";
    },
    instigator: "",
    dotColor: blue,
    dotBorder: "",
    borderColor: "blue",
  },
  data_viewed: {
    text: () => {
      return "Data viewed";
    },
    instigator: "",
    dotColor: blue,
    dotBorder: "",
    borderColor: "blue",
  },
  data_annotations_adjusted_and_report_regenerated: {
    text: () => {
      return "Data annotations adjusted and report regenerated";
    },
    instigator: "",
    dotColor: blue,
    dotBorder: "",
    borderColor: "blue",
  },
  study_interpretation_completed: {
    text: () => {
      return "Study interpretation completed";
    },
    instigator: "",
    dotColor: blue,
    dotBorder: "",
    borderColor: "blue",
  },
  patient_opened_activation_email: {
    text: () => {
      return "Patient opened activation email";
    },
    instigator: "",
    dotColor: orange,
    dotBorder: "",
    borderColor: "orange",
  },
  patient_downloaded_the_app: {
    text: () => {
      return "Patient downloaded the app";
    },
    instigator: "",
    dotColor: orange,
    dotBorder: "",
    borderColor: "orange",
  },
  patient_device_type: {
    text: (meta) => {
      return `Patient device type: ${meta.device_type}`;
    },
    instigator: "",
    dotColor: green,
    dotBorder: "",
    borderColor: "green",
  },
  patient_switched_to_different_electronic_device: {
    text: () => {
      return "Patient switched to different electronic device";
    },
    instigator: "",
    dotColor: orange,
    dotBorder: "",
    borderColor: "orange",
  },
  patient_consented_to_forms: {
    text: () => {
      return "Patient consented to forms";
    },
    instigator: "",
    dotColor: orange,
    dotBorder: "",
    borderColor: "orange",
  },
  patient_completed_or_verified_profile_information: {
    text: () => {
      return "Patient completed/verified profile information";
    },
    instigator: "",
    dotColor: orange,
    dotBorder: "",
    borderColor: "orange",
  },
  patient_completed_in_take_survey_name_of_survey: {
    text: () => {
      return "Patient completed in take survey name of survey";
    },
    instigator: "",
    dotColor: orange,
    dotBorder: "",
    borderColor: "orange",
  },
  temperature_inside_and_outside: {
    text: () => {
      return "Temperature inside and outside";
    },
    instigator: "",
    dotColor: green,
    dotBorder: "",
    borderColor: "green",
  },
  patient_uses_phone: {
    text: () => {
      return "patient_uses_phone";
    },
    instigator: "",
    dotColor: orange,
    dotBorder: "",
    borderColor: "orange",
  },
  sensor_out_of_range: {
    text: (meta) => {
      return `Sensor out of range for ${meta.duration_in_minutes} minutes`;
    },
    instigator: "",
    dotColor: green,
    dotBorder: "",
    borderColor: "green",
  },
  processing_data_night: {
    text: (meta) => {
      return `Processing data night ${meta.night}`;
    },
    instigator: "",
    dotColor: green,
    dotBorder: "",
    borderColor: "green",
  },
  data_night_processed: {
    text: (meta) => {
      return `Data night ${meta.night} processed`;
    },
    instigator: "",
    dotColor: green,
    dotBorder: "",
    borderColor: "green",
  },
  data_under_review_by_ectosense: {
    text: (meta) => {
      return `Data night ${meta.night} under review by ectosense`;
    },
    instigator: "",
    dotColor: green,
    dotBorder: "",
    borderColor: "green",
  },
  patient_completed_morning_survey: {
    text: () => {
      return "Patient completed morning survey";
    },
    instigator: "",
    dotColor: green,
    dotBorder: "",
    borderColor: "green",
  },
  reason_why_failed: {
    text: (meta) => {
      return `${meta.reason}`;
    },
    instigator: "",
    dotColor: green,
    dotBorder: "",
    borderColor: "green",
  },
  patient_visited_instruction_to_improve_next_recording: {
    text: () => {
      return "Patient visited instruction to improve next recording";
    },
    instigator: "",
    dotColor: orange,
    dotBorder: "",
    borderColor: "orange",
  },
  test_extended: {
    text: (meta) => {
      return `Test extended with ${meta.number_of_extensions} night to ${meta.total_nights} nights in total`;
    },
    instigator: "",
    dotColor: green,
    dotBorder: "",
    borderColor: "green",
  },
  patient_completed_experience_survey: {
    text: () => {
      return "Patient completed experience survey";
    },
    instigator: "",
    dotColor: green,
    dotBorder: "",
    borderColor: "green",
  },
  tracking_code: {
    text: (meta) => {
      return `Tracking code: ${meta.code}`;
    },
    instigator: "",
    dotColor: green,
    dotBorder: "",
    borderColor: "green",
  },
};
