import { mapValues, groupBy } from "lodash";

import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Mention from "@ckeditor/ckeditor5-mention/src/mention";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Font from "@ckeditor/ckeditor5-font/src/font";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock";
import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";

export const editorSettings = (variables) => {
  const obj = {
    variables,
    editorConfiguration: {
      plugins: [
        BlockQuote,
        Autoformat,
        Indent,
        IndentBlock,
        Heading,
        Essentials,
        Bold,
        Italic,
        Paragraph,
        Mention,
        Link,
        List,
        Font,
        PasteFromOffice,
      ],
      fontFamily: {
        options: [
          "default",
          "Ubuntu, Arial, sans-serif",
          "Ubuntu Mono, Courier New, Courier, monospace",
        ],
      },
      fontColor: {
        colors: [
          {
            color: "hsl( 0, 0%, 0%)",
            label: "Black",
          },
          {
            color: "hsl( 0, 100%, 25%)",
            label: "Maroon",
          },
          {
            color: "hsl( 120, 100%, 25%)",
            label: "Green",
          },
          {
            color: "hsl( 60, 100%, 25%)",
            label: "Olive",
          },
          {
            color: "hsl( 240, 100%, 25%)",
            label: "Navy",
          },
          {
            color: "hsl( 300, 100%, 25%)",
            label: "Purple",
          },
          {
            color: "hsl( 180, 100%, 25%)",
            label: "Teal",
          },
          {
            color: "hsl( 0, 0%, 75%)",
            label: "Silver",
          },
          {
            color: "hsl( 0, 0%, 50%)",
            label: "Grey",
          },
          {
            color: "hsl( 0, 100%, 50%)",
            label: "Red",
          },
          {
            color: "hsl( 120, 100%, 50%)",
            label: "Lime",
          },
          {
            color: "hsl( 60, 100%, 50%)",
            label: "Yellow",
          },
          {
            color: "hsl( 240, 100%, 50%)",
            label: "Blue",
          },
        ],
      },
      toolbar: {
        items: [
          "heading",
          "|",
          "outdent",
          "indent",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "subscript",
          "superscript",
          "blockQuote",
          "link",
          // "fontFamily",
          "fontColor",
          "bulletedList",
          "numberedList",
          "uploadImage",

          "undo",
          "redo",
          "list",
        ],
      },
      indentBlock: {
        classes: [
          "custom-block-indent-a",
          "custom-block-indent-b",
          "custom-block-indent-c",
        ],
      },
      mention: {
        feeds: [
          {
            marker: "@",
            feed: getInterpretationFeedItems,
            minimumCharacters: 0,
            // itemRenderer: customItemRenderer,
          },
        ],
      },
    },
  };
  function getInterpretationFeedItems(queryText) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const itemsToDisplay = variables.filter(isItemMatching).slice(0, 10);
        resolve(itemsToDisplay);
      }, 100);
    });

    function isItemMatching(item) {
      const searchString = queryText.toLowerCase();
      return item.id.toLowerCase().includes(searchString);
    }
  }
  return obj.editorConfiguration;
};

// function customItemRenderer(item) {
//   const itemElement = document.createElement("span");

//   itemElement.classList.add("custom-item");
//   itemElement.id = `mention-list-item-id-${item.userId}`;
//   itemElement.textContent = `${item.name} `;

//   const usernameElement = document.createElement("span");

//   usernameElement.classList.add("custom-item-username");
//   usernameElement.textContent = item.id;

//   itemElement.appendChild(usernameElement);

//   return itemElement;
// }

export const defaultSectionEnums = {
  logo: "Company logo",
  header: "Header",
  sleep_center_info: "Sleep center info",
  test_results: "Test results",
  survey_results: "Survey results",
  physician_info: "Physician info",
  glossary: "Glossary",
  intro_text: "Intro Text",
  footer: "Footer",
};

export const completionSectionEnums = {
  findings: "Findings",
  diagnosis: "Diagnosis",
  recommendations: "Recommendations",
};

export const parserHelper = function (html, orginalVariables) {
  if (html === "") return "";
  const start = "@#";
  const end = "#@";

  const parsedContent = parsedWithValuesText(
    html,
    orginalVariables,
    start,
    end
  );
  if (!parsedContent) {
    return "<div style='color: red'>Failed to parse. The variables inside snippet no longer exists for interpretation</div>";
  }
  return parsedContent;
};

function parsedWithValuesText(
  html,
  orginalVariables,
  beforeValChar,
  afterValChar
) {
  if (html.indexOf(beforeValChar) !== -1) {
    let text = "";

    let startIdx = html.indexOf("@#");
    if (startIdx === -1) {
      return "";
    }
    startIdx += beforeValChar.length;

    let endIdx = html.indexOf(afterValChar, startIdx);
    if (endIdx === -1) {
      return "";
    }

    let val = html.substring(startIdx, endIdx).trim();
    let replaceCharacters = `@#${val}#@`;
    let re = new RegExp(replaceCharacters, "g");

    if (!orginalVariables[val]) {
      return false;
    }
    text = html.replace(re, orginalVariables[val]);

    return parsedWithValuesText(
      text,
      orginalVariables,
      beforeValChar,
      afterValChar
    );
  }
  return html;
}

export const groupTemplatesByCategory = (templates) => {
  return mapValues(groupBy(templates, "category._id"));
};

export const getCategoryName = (groupedTemplates, category) => {
  return groupedTemplates[category][0]["category"]["name"];
};

export const getAvailableSection = (object) => {
  let obj = {};
  for (let i in object) {
    if (object[i]["available"] === true) {
      obj[i] = object[i];
    }
  }
  return obj;
};
