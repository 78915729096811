import moment from "moment";
import { convertPatientBirthday, parseISO } from "../util.api";
import { convertRecording } from "./recordingTransformer";

export function convertTest(test) {
  return {
    id: test._id,
    patientId: test.patient._id,
    patientFirstname: test.patient.first_name,
    patientLastname: test.patient.last_name,
    patientEmail: test.patient.email,
    patientBirthday: convertPatientBirthday(test.patient.dob),
    patientPhone: test.patient.phone,
    numberOfRecordings: test.number_of_recordings,
    unitId: test.unit?._id || test.unit,
    recordings: test.recordings.map(convertRecording).reverse(),
    // offline: test.offline,
    updatedAt: moment(test.modified_at, moment.ISO_8601).toDate(),
    createdAt: moment(test.created_at, moment.ISO_8601).toDate(),
    isResmedAustraliaTest:
      (test.custom_flags && test.custom_flags.resmed_australia) ||
      test.service === "RESMED_AUS",
    isResmedFranceTest:
      (test.custom_flags && test.custom_flags.resmed_france) ||
      test.service === "RESMED_FR",
  };
}

export function convertTestReport(report) {
  if (!report.approved || report.archived) {
    return null;
  }

  return {
    id: report._id,
    updatedAt: parseISO(report.modified_at),
    archived: report.archived,
    approved: report.approved,
    type: report.report_type,
    read: report.read,
    file: report.file,
    test: report.test,
  };
}

export function convertRecordingReport(report) {
  if (!report.approved || report.archived) {
    return null;
  }

  return {
    id: report._id,
    updatedAt: parseISO(report.modified_at),
    archived: report.archived,
    approved: report.approved,
    type: report.report_type,
    read: report.read,
    file: report.file,
    recording: report.recording,
  };
}
