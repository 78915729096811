import React, { Suspense, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useBeforeunload } from "react-beforeunload";

import Study from "pages/Study";
import { connect } from "react-redux";
import { FullscreenSpinner } from "core/components/FullScreenSpinner";

import { getVariablesAction } from "store/evaluation/evaluation.actions";

const StudyLayout = (props) => {
  const { tests, evaluation } = props;
  useEffect(() => {
    async function f() {
      const {
        test: { _id: test_id },
      } = props.tests;

      // TODO
      await props.getVariablesAction(test_id);
    }
    f();
  }, []);
  const renderAuthenticatedRoot = () => {
    return (
      <Redirect
        to={{
          pathname: "/study",
        }}
      />
    );
  };

  // TODO
  useBeforeunload(() => {
    if (props.evaluation.draft.status === "success") {
      return "You’ll lose your data!";
    }
  });

  if (evaluation.variables.status !== "success") {
    return <FullscreenSpinner />;
  }
  return (
    <div className="App">
      <Suspense fallback={<FullscreenSpinner />}>
        <Switch>
          <Route exact path="/" render={renderAuthenticatedRoot} />
          <Route
            path="/study"
            render={(props) => (
              <Study
                {...props}
                test={tests.test}
                // regionUs={tests.test.unit.report_settings.region}
                sidebarProps={{
                  testResults: {
                    variables: evaluation.variables,
                    totalNights: evaluation.variables.data[2]?.Total_Nights,
                  },
                }}
              />
            )}
          />
        </Switch>
      </Suspense>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    system: state.system,
    tests: state.tests,
    evaluation: state.evaluation,
  };
}

export default connect(mapStateToProps, { getVariablesAction })(StudyLayout);
