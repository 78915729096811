import React from "react";
import moment from "moment";
import { List } from "antd";
import { eventValueMap } from "../constants";

interface Props {
  list: any;
}

class ListEvents extends React.Component<Props> {
  render() {
    const { list } = this.props;
    return (
      <div className="list-container">
        {list.map((l, i) => {
          return (
            <React.Fragment key={i}>
              <div
                key={i}
                className={`list border-${eventValueMap[l.event].borderColor}`}
              >
                <List>
                  <List.Item
                    actions={[
                      <span className="text_3">
                        {moment.unix(l.timestamp).format("h:mm A")}
                      </span>,
                    ]}
                  >
                    <List.Item.Meta
                      className="text_1"
                      title={eventValueMap[l.event].text(l.meta)}
                    />
                  </List.Item>
                </List>
              </div>
              {l.refToSecondary.map((e, i) => {
                return (
                  <div
                    key={i}
                    className={`list border-${
                      eventValueMap[e.event].borderColor
                    }`}
                  >
                    <List>
                      <List.Item
                        key={i}
                        actions={[
                          <span className="text_3">
                            {moment.unix(e.timestamp).format("h:mm A")}
                          </span>,
                        ]}
                      >
                        <List.Item.Meta
                          className="text_1"
                          title={eventValueMap[e.event].text(e.meta)}
                        />
                      </List.Item>
                    </List>
                  </div>
                );
              })}
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}

export default ListEvents;
