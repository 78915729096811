import React, { useState } from "react";
import { Row, Col, Card, Select, Space, Modal } from "antd";

import InterpretingEditors from "./InterpretingEditors";
import SnippetsDrawer from "./SnippetsDrawer";
import CircleSpinner from "core/components/CircleSpinner";

import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import {
  getCategoryName,
  groupTemplatesByCategory,
} from "containers/Evaluation/util";
import { FullscreenSpinner } from "core/components/FullScreenSpinner";

const { Option, OptGroup } = Select;
const { confirm } = Modal;

const InterpretationSection = (props) => {
  const {
    test,
    templates,
    template,
    snippets,
    snippet,
    variables,
    preview,
    signOff,
    draft,
    selectedTemplate,
    handleTemplateSelect,
    handleTemplateEditClicked,
    handleTemplateDeleteClicked,
    handleGetSnippet,
    handleCreateSnippet,
    handleSnippetEditClicked,
    handleSnippetDeleteClicked,
    handleSignOff,
    handleSeePreview,
    setShowSnippetDrawer,
    showSnippetDrawer,
    handleInterpretationDraft,
    customFlags,
  } = props;

  const [injectingToType, setInjectingToType] = useState("");
  const [snippetInjectedContent, setSnippetInjectedContent] = useState("");
  const [showPreview, setShowPreview] = useState(false);

  const handleGetSnippetsAndSettingType = (type) => {
    setInjectingToType(type);
    props.handleGetSnippets(type);
  };

  const handleSnippetInjection = (content) => {
    setSnippetInjectedContent(content);
  };

  const handleTemplateDeleteConfirm = (e, c) => {
    e.stopPropagation();
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete ${c.name}`,
      okText: "Delete",

      cancelText: "Cancel",
      onOk() {
        handleTemplateDeleteClicked(e, c._id);
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };

  const handleShowPreview = (data) => {
    handleSeePreview(data);
    setShowPreview(true);
  };

  const groupedTemplates = groupTemplatesByCategory(templates.data);

  return (
    <Row align="middle">
      {signOff.status === "initialized" && (
        <Col span={24} className="mt-5 center">
          <FullscreenSpinner />
        </Col>
      )}
      <Col span={showSnippetDrawer ? 16 : 24}>
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <Card
              title="Template"
              extra={
                <div
                  className="green cursor-pointer"
                  onClick={() => handleTemplateSelect("create-new-template")}
                >
                  <span>
                    <PlusOutlined />
                  </span>
                  <span className="ml-2">Create new template</span>
                </div>
              }
            >
              <Select
                defaultValue={
                  template.status === "success"
                    ? template.data._id
                    : // : templates.data[0]["_id"]
                      "Select a template"
                }
                placeholder="Select Template"
                style={{ width: "50%" }}
                onChange={handleTemplateSelect}
                className="select-template-dropdown"
                size={"large"}
              >
                {Object.keys(groupedTemplates).map((t, i) => (
                  <OptGroup
                    key={i}
                    // label={groupedTemplates[t][0]['category']['name']}
                    label={getCategoryName(groupedTemplates, t)}
                  >
                    {groupedTemplates[t].map((c, i) => (
                      <Option
                        key={c._id}
                        value={c._id}
                        className="template-list-option"
                      >
                        <>
                          <span>{c.name}</span>
                          {c.parent_template && (
                            <span className="extra">
                              <Space size="middle">
                                <EditOutlined
                                  className="icon"
                                  onClick={(e) =>
                                    handleTemplateEditClicked(e, c._id)
                                  }
                                />
                                <DeleteOutlined
                                  className="icon"
                                  onClick={(e) =>
                                    // handleTemplateDeleteClicked(e, c._id)
                                    handleTemplateDeleteConfirm(e, c)
                                  }
                                />
                              </Space>
                            </span>
                          )}
                        </>
                      </Option>
                    ))}
                  </OptGroup>
                ))}
              </Select>
            </Card>
          </Col>

          {template.status === "initialized" && (
            <Col span={24} className="mt-5 center">
              <CircleSpinner />
            </Col>
          )}
          {template.status === "success" && selectedTemplate && (
            <InterpretingEditors
              test={test}
              templateData={template.data}
              variablesData={variables.data}
              preview={preview}
              draft={draft}
              injectingToType={injectingToType}
              snippetInjectedContent={snippetInjectedContent}
              handleGetSnippets={handleGetSnippetsAndSettingType}
              handleSignOff={handleSignOff}
              showSnippetDrawer={showSnippetDrawer}
              setShowSnippetDrawer={setShowSnippetDrawer}
              showPreview={showPreview}
              setShowPreview={setShowPreview}
              handleSeePreview={handleShowPreview}
              handleInterpretationDraft={handleInterpretationDraft}
              customFlags={customFlags}
            />
          )}
        </Row>
      </Col>

      {snippets.status === "initialized" && showSnippetDrawer && (
        <Col span={8} className="center">
          <CircleSpinner />
        </Col>
      )}

      {snippets.status === "success" && showSnippetDrawer && (
        <SnippetsDrawer
          snippetsData={snippets.data}
          snippet={snippet}
          variablesData={variables.data}
          handleGetSnippet={handleGetSnippet}
          handleCreateSnippet={handleCreateSnippet}
          handleSnippetEditClicked={handleSnippetEditClicked}
          handleSnippetDeleteClicked={handleSnippetDeleteClicked}
          handleSnippetInjection={handleSnippetInjection}
          showSnippetDrawer={showSnippetDrawer}
          setShowSnippetDrawer={setShowSnippetDrawer}
        />
      )}
    </Row>
  );
};

export default InterpretationSection;
