import axios from "axios";

const BASE_URL = __DEV__ ? DEV_URL : SERVER_URL;
export const ACCOUNTS_BASE_URL = __DEV__ ? ACCOUNTS_DEV_URL : ACCOUNTS_URL;
const SURVEY_BASE_URL = __DEV__ ? SURVEY_DEV_URL : SURVEY_URL;
const IP_BASE_URL = __DEV__ ? IP_DEV_URL : IP_URL;

const SIGNING_SERVICE = "HCP";

export let apiAccountsInstance = axios.create({
  baseURL: ACCOUNTS_BASE_URL,
});

export const surveyApiInstance = axios.create({
  baseURL: SURVEY_BASE_URL,
  headers: {
    "x-api-key": EST,
    "x-manufacturer-id": "browser",
    "x-signing-service": "HCP",
  },
});

export let apiInstance = axios.create({
  baseURL: BASE_URL,
});

export let ipApiInstance = axios.create({
  baseURL: IP_BASE_URL,
});

function getApiInstanceWithToken(token: string, service: string | null) {
  const api = axios.create({
    baseURL: BASE_URL,
    headers: {
      "x-auth-token": token,
      "x-signing-service": service || SIGNING_SERVICE,
    },
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (
        error.response &&
        error.response.status === 401 &&
        !error.response.config.url.endsWith("/me")
      ) {
        window.location.replace(`${HCP_DASHBOARD_URL}/logout`);
      }
      return Promise.reject(error);
    }
  );

  return api;
}

function getIpApiInstanceWithToken(token: string, service: string | null) {
  const api = axios.create({
    baseURL: IP_BASE_URL,
    headers: {
      "x-auth-token": token,
      "x-signing-service": service || SIGNING_SERVICE,
    },
  });
  return api;
}

export function setAuthToken(
  token: string | null,
  service: string = SIGNING_SERVICE
): void {
  if (token === null) {
    apiInstance = axios.create({
      baseURL: BASE_URL,
    });
  } else {
    apiInstance = getApiInstanceWithToken(token, service);
    ipApiInstance = getIpApiInstanceWithToken(token, service);
    apiAccountsInstance = axios.create({
      baseURL: ACCOUNTS_BASE_URL,
      headers: {
        "x-auth-token": token,
        "x-signing-service": service || SIGNING_SERVICE,
      },
    });
  }
}
