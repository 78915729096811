import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

const index = () => {
  return <Spin indicator={antIcon} />;
};

export default index;
