export const cookieUtils = {
  createCookie(name, value, expires, path, domain) {
    var cookie = name + "=" + escape(value) + ";";

    if (expires) {
      // If it's a date
      if (expires instanceof Date) {
        // If it isn't a valid date
        if (isNaN(expires.getTime())) expires = new Date();
      } else
        expires = new Date(
          new Date().getTime() + parseInt(expires) * 1000 * 60 * 60 * 24
        );

      cookie += "expires=" + expires.toGMTString() + ";";
    }

    if (path) cookie += "path=" + path + ";";
    if (domain) cookie += "domain=" + domain + ";";

    document.cookie = cookie;
  },
  getCookie(name) {
    let cookie: any = document.cookie;
    cookie = cookie.split(name + "=");
    let cookieValue, cookieExists;
    //if the split length is 2 that means cookie exists
    if (cookie.length === 2 && cookie[1]) {
      cookieValue = cookie[1].split(";")[0];
      cookieExists = true;
    } else cookieExists = false;
    return {
      cookieExists,
      cookieValue: cookieValue ? cookieValue : null,
    };
  },
};

export const parseCookie = () =>
  document.cookie &&
  document.cookie
    .split(";")
    .map((v) => v.split("="))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      return acc;
    }, {});
