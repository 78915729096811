import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import { rootReducer } from "store";
import "styles/main.scss";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { initSystemThunk } from "store/system/system.actions";
import { composeWithDevTools } from "redux-devtools-extension";

const middlewares = [thunk];

function initStore() {
  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middlewares))
  );
  store.dispatch(initSystemThunk());
  return store;
}

function renderApp(store) {
  ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}>
        <React.Fragment>
          <App />
        </React.Fragment>
      </Provider>
    </BrowserRouter>,
    document.getElementById("root")
  );
}

renderApp(initStore());

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
