export const documentEnums = {
  ipReport: "Interpretation report",
  summaryReport: "Summary report",
  night1: "Night 1 report",
  night2: "Night 2 report",
  night3: "Night 3 report",
  night4: "Night 4 report",
  night5: "Night 5 report",
  night6: "Night 6 report",
  night7: "Night 7 report",
};

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}
