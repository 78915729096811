import React, { Suspense, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { Result, Button } from "antd";
// import "./App.css";
import StudyLayout from "Layouts/StudyLayout";
import { FullscreenSpinner } from "core/components/FullScreenSpinner";
import { getTest } from "store/tests/tests.actions";
import { manageTestId } from "api/util.api";

function App(props) {
  useEffect(() => {
    const test_id = manageTestId();
    if (!!test_id) {
      props.getTest(test_id);
    }
  }, []);

  const {
    system: { authenticated },
    tests: { test },
  } = props;

  if (test && test.error) {
    return (
      <Result
        status="403"
        title="Where's the test ID?"
        subTitle="Test ID seems to be missing, go back to HCP dashboard and click on a Patient's name"
        extra={
          <Button
            type="primary"
            onClick={() => window.location.replace(`${HCP_DASHBOARD_URL}`)}
          >
            GO TO DASHBOARD
          </Button>
        }
      />
    );
  }

  if (test && !test.error) {
    return (
      <Suspense fallback={<FullscreenSpinner />}>
        <Switch>
          <Route
            path="/"
            render={() => (authenticated ? <StudyLayout /> : null)}
          />
        </Switch>
      </Suspense>
    );
  }
  return <FullscreenSpinner />;
}

function mapStateToProps(state) {
  return {
    system: state.system,
    tests: state.tests,
  };
}

export default connect(mapStateToProps, { getTest })(App);
