import React from "react";

import { Spinner } from "../Spinner";
import "./styles.scss";

export const FullscreenSpinner: React.FC = () => {
  return (
    <div className="fullscreen-spinner">
      <Spinner />
    </div>
  );
};
