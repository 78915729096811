import React, { useState } from "react";
import { Row, Col, Card, Divider, Image } from "antd";
import { EditOutlined } from "@ant-design/icons";

import { data } from "../data/data";

import "../styles.scss";
import moment from "moment";

const Summary = (props) => {
  const {
    test,
    testResults: { variables },
  } = props;

  const bindingVariables = variables.data[2];
  const [isUsRegion] = useState(() => (bindingVariables.region ? true : false));
  const calcBMI = (weight, height) => {
    return Math.ceil(weight / Math.pow(height / 100, 2));
  };

  const handleNotesEditClick = () => {
    props.history.push("/study/notes");
  };

  return (
    <div className="page-summary">
      <Row gutter={[32, 32]}>
        <Col span={8} className="partA">
          <Row gutter={[32, 32]}>
            <Col span={24} className="personal">
              <Card>
                <div className="header">
                  <span className="text_title">{`${test.patient.first_name} ${test.patient.last_name}`}</span>
                  {/* <span className="icon green">Test settings</span> */}
                </div>
                <div className="patient-details">
                  <Divider orientation="left" plain>
                    Patient Details
                  </Divider>
                  <Row gutter={[16, 16]}>
                    {/* <Col span={8}>Female</Col> */}
                    <Col span={8}>{moment(data.dob).format("LL")}</Col>
                    <Col span={8}>BMI {calcBMI(data.Weight, data.Height)}</Col>
                    <Col span={8}>{data.Height} cm</Col>
                    <Col span={8}>{data.Weight} kg</Col>
                  </Row>
                </div>
                {false && (
                  <div className="questionnaires">
                    <Divider orientation="left" plain>
                      Questionnaires
                    </Divider>
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <div>ESS</div>
                        <div>12</div>
                      </Col>
                      <Col span={8}>
                        <div>PSQI</div>
                        <div>13</div>
                      </Col>
                    </Row>
                  </div>
                )}

                {test.notes && (
                  <div className="notes">
                    <Divider orientation="left" plain>
                      Notes
                      <span className="ml-2">
                        <EditOutlined
                          className="icon"
                          onClick={(e) => handleNotesEditClick()}
                        />
                      </span>
                    </Divider>
                    <Row gutter={[16, 16]}>
                      <Col span={24}>{test.notes}</Col>
                    </Row>
                  </div>
                )}
              </Card>
            </Col>

            <Col span={24} className="calculations">
              <Card title="Test info">
                <div>
                  <span>Number of nights</span>
                  <span className="float-right">{data.Total_Nights}</span>
                </div>
                <div>
                  <span>Number of failed nights</span>
                  <span className="float-right">{data.Failed_Nights}</span>
                </div>
                <div>
                  <span>Mean percentage of rejected recording</span>
                  <span className="float-right">{data.Mean_Reject}%</span>
                </div>
              </Card>
            </Col>

            <Col span={24} className="calculations">
              <Card title="Pulse Rate">
                <div>
                  <span>Mean Pulse Rate</span>
                  <span className="float-right">{data.Mean_PR} bpm</span>
                </div>
                <div>
                  <span>Minimum PR</span>
                  <span className="float-right">{data.Min_PR} bpm</span>
                </div>
                <div>
                  <span>Maximum PR</span>
                  <span className="float-right">{data.Max_PR} bpm</span>
                </div>
                <div>
                  <span>{"Pulse Rate > 100 bpm"}</span>
                  <span className="float-right">{data.Beats_greater_100}%</span>
                </div>
                <div>
                  <span>{"Pulse Rate < 40 bpm"}</span>
                  <span className="float-right">{data.Beats_less_40}%</span>
                </div>
                {isUsRegion && (
                  <div>
                    <span>Ectopic beats</span>
                    <span className="float-right">
                      {data.Ectopic}% of beats
                    </span>
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        </Col>

        <Col span={16} className="partB">
          <Row gutter={[32, 32]}>
            <Col span={24}>
              <Card title="Apnea Hypopnea Index (AHI) estimate">
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    {/* <Plot
                      data={[
                        {
                          type: "bar",
                          x: [1, 2, 3],
                          y: [16, 17, 14],
                          text: [16, 17, 14],
                          marker: {
                            color: "red",
                          },
                          width: 0.5,
                        },
                      ]}
                      config={{
                        displaylogo: false,
                        displayModeBar: false,
                      }}
                      layout={{
                        width: 300,
                        height: 150,
                        margin: {
                          l: 40,
                          r: 30,
                          b: 15,
                          t: 10,
                        },
                        yaxis: {
                          title: {
                            text: "AHI",
                            font: {
                              // family: 'Courier New, monospace',
                              size: 14,
                              color: "#444",
                            },
                          },
                        },
                      }}
                    /> */}
                    {bindingVariables.summaryImages ? (
                      <Image src={bindingVariables?.summaryImages["ahi"]} />
                    ) : null}
                  </Col>
                  <Col offset={1} span={6} className="calculations">
                    {isUsRegion && (
                      <div className="mb-5">
                        <div>
                          <span>Max severity</span>
                          <span>{`${data.Max_Severity} events/h`}</span>
                        </div>
                        <div>
                          <span>Median severity</span>
                          <span>{`${data.Median_Severity} events/h`}</span>
                        </div>
                        <div>
                          <span>Severity of mean AHI</span>
                          <span>{data.Mean_Severity} events/h</span>
                        </div>
                      </div>
                    )}
                    <div>
                      <span>Max pAHI</span>
                      <span>{`${data.Max_AHI} events/h`}</span>
                    </div>
                    <div>
                      <span>Mean pAHI</span>
                      <span>{`${data.Avg_AHI} events/h`}</span>
                    </div>
                    <div>
                      <span>Min pAHI</span>
                      <span>{data.Min_AHI} events/h</span>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={24}>
              <Card title="Oxygen Desaturation Index (ODI)">
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    {/* <Plot
                      data={[
                        {
                          type: "bar",
                          x: [1, 2, 3],
                          y: [16, 17, 14],
                          text: [16, 17, 14],
                          marker: {
                            color: "orange",
                          },
                          width: 0.5,
                        },
                      ]}
                      config={{
                        displaylogo: false,
                        displayModeBar: false,
                      }}
                      layout={{
                        width: 300,
                        height: 150,
                        margin: {
                          l: 40,
                          r: 30,
                          b: 15,
                          t: 10,
                        },
                        yaxis: {
                          title: {
                            text: "ODI",
                            font: {
                              // family: 'Courier New, monospace',
                              size: 14,
                              color: "#444",
                            },
                          },
                        },
                      }}
                    /> */}
                    {bindingVariables.summaryImages ? (
                      <Image src={bindingVariables?.summaryImages["spo2"]} />
                    ) : null}
                  </Col>
                  <Col offset={1} span={6} className="calculations">
                    <div>
                      <span>{"Max ODI ≥ 3"}</span>
                      <span>{data.Max_ODI_3} events/h</span>
                    </div>
                    <div>
                      <span>{"Mean ODI ≥ 3"}</span>
                      <span>{data.Avg_ODI_3} events/h</span>
                    </div>
                    <div>
                      <span>{"Min ODI ≥ 3"}</span>
                      <span>{data.Min_ODI_3} events/h</span>
                    </div>

                    <div className="mt-5">
                      <span>{"Max ODI ≥ 4"}</span>
                      <span>{data.Max_ODI_4} events/h</span>
                    </div>
                    <div>
                      <span>{"Mean ODI ≥ 4"}</span>
                      <span>{data.Avg_ODI_4} events/h</span>
                    </div>
                    <div>
                      <span>{"Min ODI ≥ 4"}</span>
                      <span>{data.Min_ODI_4} events/h</span>
                    </div>
                  </Col>
                  <Col offset={1} span={7} className="calculations">
                    <div>
                      <span>Mean T90</span>
                      <span>{data.Avg_T90}%</span>
                    </div>
                    <div>
                      <span>Minimum SpO2</span>
                      <span>{data.Min_SPO2} events/h</span>
                    </div>
                    <div>
                      <span>Maximum SpO2</span>
                      <span>{data.Max_SPO2} events/h</span>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={24}>
              <Card title="Total Sleep Time">
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    {/* <Plot
                      data={[
                        {
                          type: "bar",
                          x: [1, 2, 3],
                          y: [16, 17, 14],
                          text: [16, 17, 14],
                          marker: {
                            color: "green",
                          },
                          width: 0.5,
                        },
                      ]}
                      config={{
                        displaylogo: false,
                        displayModeBar: false,
                      }}
                      layout={{
                        width: 300,
                        height: 150,
                        margin: {
                          l: 40,
                          r: 30,
                          b: 15,
                          t: 10,
                        },
                        yaxis: {
                          title: {
                            text: "TST",
                            font: {
                              // family: 'Courier New, monospace',
                              size: 14,
                              color: "#444",
                            },
                          },
                        },
                      }}
                    /> */}
                    {bindingVariables.summaryImages ? (
                      <Image src={bindingVariables?.summaryImages["tst"]} />
                    ) : null}
                  </Col>
                  <Col offset={1} span={6} className="calculations">
                    <div>
                      <span>Max TST</span>
                      <span>{data.Max_TST}</span>
                    </div>
                    <div>
                      <span>Mean TST</span>
                      <span>{data.Mean_TST}</span>
                    </div>
                    <div>
                      <span>Min TST</span>
                      <span>{data.Min_TST}</span>
                    </div>
                    {isUsRegion && (
                      <div className="mt-5">
                        <div>
                          <span>Max SE</span>
                          <span>{data.Max_SE}</span>
                        </div>
                        <div>
                          <span>Mean SE</span>
                          <span>{data.Mean_SE}</span>
                        </div>
                      </div>
                    )}
                  </Col>
                  {isUsRegion && (
                    <Col offset={1} span={8} className="calculations">
                      <div>
                        <span>Max Total REM Time</span>
                        <span>{data.Max_TST}</span>
                      </div>
                      <div>
                        <span>Mean Total REM Time</span>
                        <span>{data.Mean_TST}</span>
                      </div>
                      <div>
                        <span>Min Total REM Time</span>
                        <span>{data.Min_TST}</span>
                      </div>
                    </Col>
                  )}
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Summary;
