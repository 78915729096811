import React, { useState } from "react";
import { Row, Col, Divider, Form, Button, Select, Input } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import { editorSettings } from "containers/Evaluation/util";

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const AddSnippetDrawer = (props) => {
  const [addSnippetForm] = Form.useForm();

  const {
    variablesData,
    handleCreateSubmit,
    handleEditorContentChange,
    setIsSnippetList,
    setIsAddDrawer,
  } = props;

  const [editorVariables] = variablesData;

  const [editorConfig] = useState(
    editorSettings(editorVariables.convertedTemplatingVariables)
  );

  return (
    <div className="drawer">
      <div className="snippet-list-section">
        <div className="header">
          <div className="components-container">
            <Row align="middle">
              <Col span={6}>
                <div className="text_title">New snippet</div>
              </Col>
            </Row>
          </div>
        </div>

        <Divider className="divider" />

        <div className="body">
          <Row gutter={[32, 32]}>
            <Col span={24}>
              <Form
                name="snippet-add-edit-form"
                {...formItemLayout}
                layout={null}
                form={addSnippetForm}
                initialValues={{ belongs_to: "FINDINGS" }}
                onFinish={handleCreateSubmit}
              >
                <Form.Item
                  required={false}
                  name="name"
                  label="Snippet name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter snippet name!",
                      type: "string",
                    },
                  ]}
                  // hasFeedback
                >
                  <Input placeholder="New snippet name" />
                </Form.Item>
                <Form.Item name="belongs_to" label="Template section">
                  <Select placeholder="Select template section">
                    {[
                      { key: "FINDINGS", value: "Findings" },
                      { key: "DIAGNOSIS", value: "Diagnosis" },
                      {
                        key: "RECOMMENDATIONS",
                        value: "Recommendations",
                      },
                    ].map((section, i) => {
                      return (
                        <Option key={i} value={section.key}>
                          {section.value}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <div className="editor">
                    <CKEditor
                      height={497}
                      editor={ClassicEditor}
                      config={{
                        ...editorConfig,
                        placeholder: "Write snippet text here.",
                      }}
                      data=""
                      onReady={(editor) => {
                        editor.editing.view.change((writer) => {
                          writer.setStyle(
                            "height",
                            "497px",
                            editor.editing.view.document.getRoot()
                          );
                        });
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        handleEditorContentChange(data);
                      }}
                    />
                  </div>
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <div className="bottom-right-fixed">
                    <Button
                      htmlType="button"
                      style={{
                        margin: "0 8px",
                      }}
                      onClick={() => {
                        setIsSnippetList(true);
                        setIsAddDrawer(false);
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      htmlType="submit"
                      type="primary"
                      // onClick={}
                    >
                      Save
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AddSnippetDrawer;
