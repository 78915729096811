import moment from "moment";
import { primaryEvents, secondaryEvents } from "./constants";
import { uniq } from "lodash";

export function composeEventType(evtData) {
  // let evtMap = {};
  let evtRes = [];
  // let temp;
  let temp2;

  // for (let evt in evtData) {
  //   if (primaryEvents[evtData[evt].event]) {
  //     temp = evtMap[evtData[evt].event] = {
  //       event: evtData[evt].event,
  //       timestamp: evtData[evt].timestamp,
  //       meta: evtData[evt].meta,
  //       refToSecondary: [],
  //     };
  //   }

  //   if (secondaryEvents[evtData[evt].event]) {
  //     temp.refToSecondary.push(evtData[evt]);
  //   }
  // }
  for (let evt in evtData) {
    if (primaryEvents[evtData[evt].event]) {
      temp2 = {
        event: evtData[evt].event,
        timestamp: evtData[evt].timestamp,
        meta: evtData[evt].meta,
        refToSecondary: [],
      };
    }

    if (secondaryEvents[evtData[evt].event]) {
      temp2.refToSecondary.push(evtData[evt]);
    }

    evtRes.push(temp2);
  }
  return createTimelineMap(uniq(evtRes, "event"));
}

function createTimelineMap(evtMap) {
  let timelineMap = Object.keys(evtMap).reduce((acc, e) => {
    let date = moment.unix(evtMap[e].timestamp).format("ll");
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(evtMap[e]);
    return acc;
  }, {});
  return timelineMap;
}
