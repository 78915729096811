import {
  SystemState,
  SystemActionTypes,
  INIT_SYSTEM,
  LOGIN_REQUEST,
  LOGIN_RESPONSE,
  LOGOUT_RESPONSE,
} from "./system.types";

const initialState = {
  initialized: false,
  authenticated: false,
  expired: false,
  fetching: false,
  error: null,
  user: undefined,
};

export function systemReducer(
  state: SystemState = initialState,
  action: SystemActionTypes
) {
  switch (action.type) {
    case INIT_SYSTEM:
      return {
        ...state,
        initialized: true,
        authenticated: action.payload.authenticated,
        expired: Boolean(action.payload.expired),
        user: action.payload.user,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case LOGIN_RESPONSE:
      return {
        ...state,
        error: action.payload.error || null,
        authenticated: !action.payload.error,
        fetching: false,
        user: action.payload.user,
      };
    case LOGOUT_RESPONSE:
      return {
        ...state,
        initialized: true,
      };
    default:
      return state;
  }
}
