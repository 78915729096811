import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Checkbox,
  // Select,
  Button,
  Tooltip,
  Dropdown,
  Menu,
  Input,
} from "antd";
import { ExclamationCircleOutlined, UpOutlined } from "@ant-design/icons";
import { omit } from "lodash";

import CreateNewTemplateModal from "./CreateNewTemplateModal";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import { SaveOutlined } from "@ant-design/icons";
import { Upload, message } from "antd";

import { handleImageUpload } from "api/evaluation.api";
import {
  completionSectionEnums,
  defaultSectionEnums,
  editorSettings,
  getAvailableSection,
} from "containers/Evaluation/util";

const { Dragger } = Upload;
// const { Option, OptGroup } = Select;

const TemplateEditors = (props) => {
  const {
    templateData,
    variablesData,
    isCreateNewTemplate,
    handleCreateNewTemplate,
    handleEditTemplate,
    // handleCancelClicked,
    // handleSeePreview,
  } = props;

  const [editorVariables] = variablesData;

  const [templateNameText, setTemplateNameText] = useState(templateData.name);

  const [defaultSectionEntities, setDefaultSectionEntities] = useState(
    templateData.default_sections
  );
  const [completionSectionEntities, setCompletionSectionEntities] = useState(
    templateData.completion_sections
  );

  const [showCreateNewTemplateModal, setShowCreateNewTemplateModal] =
    useState(false);

  const [defaultSectionUploadEntities, setDefaultSectionUploadEntities] =
    useState(
      {
        logo: [
          {
            uid: templateData.default_sections.logo.uid,
            name: templateData.default_sections.logo.name,
            status: "done",
            url: templateData.default_sections.logo.text,
          },
        ],
        header: [
          {
            uid: templateData.default_sections.header.uid,
            name: templateData.default_sections.header.name,
            status: "done",
            url: templateData.default_sections.header.text,
          },
        ],
        footer: [
          {
            uid: templateData.default_sections.footer.uid,
            name: templateData.default_sections.footer.name,
            status: "done",
            url: templateData.default_sections.footer.text,
          },
        ],
      } || []
    );
  const [editorConfig] = useState(
    editorSettings(editorVariables.convertedTemplatingVariables)
  );

  // editor handlers
  const handleOnReady = (editor) => {
    editor &&
      editor.editing.view.change((writer) => {
        writer.setStyle(
          "min-height",
          "80px",
          editor.editing.view.document.getRoot()
        );
      });
  };

  // checkbox handlers
  const handleDefaultCheckboxStatusChange = (e) => {
    const entityKey = e.target.name;
    const entitySelected = e.target.checked;
    const updatedData = Object.assign({}, defaultSectionEntities);
    updatedData[entityKey] = {
      ...updatedData[entityKey],
      selected: entitySelected,
    };
    setDefaultSectionEntities(updatedData);
  };

  const handleCompletionCheckboxStatusChange = (e) => {
    const entityKey = e.target.name;
    const entitySelected = e.target.checked;
    const updatedData = Object.assign({}, completionSectionEntities);
    updatedData[entityKey] = {
      ...updatedData[entityKey],
      selected: entitySelected,
    };
    setCompletionSectionEntities(updatedData);
  };

  // text handlers
  const handleDefaultTextChange = (key, text) => {
    const entityKey = key;
    const updatedData = Object.assign({}, defaultSectionEntities);
    updatedData[entityKey] = {
      ...updatedData[entityKey],
      text: text,
    };
    setDefaultSectionEntities(updatedData);
  };

  // upload url handlers
  const handleDefaultSectionUploadEntitiesRemove = (key) => {
    var f = omit(defaultSectionUploadEntities, [key]);
    setDefaultSectionUploadEntities(f);
  };

  // upload url handlers
  const handleDefaultSectionUploadEntitiesUrl = (key, url) => {
    const entityKey = key;
    const updatedData = Object.assign({}, defaultSectionUploadEntities);
    updatedData[entityKey][0]["url"] = url;

    setDefaultSectionUploadEntities(updatedData);
  };

  const handleCompletionTextChange = (key, text) => {
    const entityKey = key;
    const updatedData = Object.assign({}, completionSectionEntities);
    updatedData[entityKey] = {
      ...updatedData[entityKey],
      text: text,
    };
    setCompletionSectionEntities(updatedData);
  };

  const composeUploadUrl = (defaultSectionEntities) => {
    if (defaultSectionEntities.logo.text.startsWith("https")) {
      let str = defaultSectionEntities.logo.text;
      let firstIndex = str.indexOf("/templates");
      let secondIndex = str.indexOf("?");
      defaultSectionEntities.logo.text = str.substring(
        firstIndex + 1,
        secondIndex
      );
    }

    if (defaultSectionEntities.header.text.startsWith("https")) {
      let str = defaultSectionEntities.header.text;
      let firstIndex = str.indexOf("/templates");
      let secondIndex = str.indexOf("?");
      defaultSectionEntities.header.text = str.substring(
        firstIndex + 1,
        secondIndex
      );
    }

    if (defaultSectionEntities.footer.text.startsWith("https")) {
      let str = defaultSectionEntities.footer.text;
      let firstIndex = str.indexOf("/templates");
      let secondIndex = str.indexOf("?");
      defaultSectionEntities.footer.text = str.substring(
        firstIndex + 1,
        secondIndex
      );
    }
  };

  const handleSubmit = () => {
    const { template, templateData } = props;
    const { _id } = template.data;

    composeUploadUrl(defaultSectionEntities);

    if (isCreateNewTemplate) {
      const { category, centre, name, parent_template } = templateData;

      let data = {
        category: category._id,
        centre,
        name,
        parent_template,
        default_sections: getAvailableSection(defaultSectionEntities),
        completion_sections: getAvailableSection(completionSectionEntities),
      };
      handleCreateNewTemplate(data);
    } else {
      let data = {
        name: templateNameText,
        default_sections: getAvailableSection(defaultSectionEntities),
        completion_sections: getAvailableSection(completionSectionEntities),
      };
      handleEditTemplate(_id, data);
    }
  };

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error("Image must smaller than 10MB!");
    }
    return isJpgOrPng && isLt10M;
  }

  const uploadPropsCompanyLogo = {
    name: "imagedata",
    // multiple: true,
    fileList: defaultSectionUploadEntities["logo"],
    maxCount: 1,
    beforeUpload(file) {
      beforeUpload(file);
    },
    async customRequest(options) {
      const uploadData = await handleImageUpload(options);
      const [imagePath, imageUrl, onSuccess] = uploadData;
      handleDefaultTextChange("logo", imagePath);
      handleDefaultSectionUploadEntitiesUrl("logo", imageUrl);
      onSuccess();
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
    // onRemove(file) {
    //   handleDefaultSectionUploadEntitiesRemove("logo");
    // },
  };

  const uploadProps = {
    name: "imagedata",
    // multiple: true,
    fileList: defaultSectionUploadEntities["header"],
    maxCount: 1,
    beforeUpload(file) {
      beforeUpload(file);
    },
    async customRequest(options) {
      const uploadData = await handleImageUpload(options);
      const [imagePath, imageUrl, onSuccess] = uploadData;
      handleDefaultTextChange("header", imagePath);
      handleDefaultSectionUploadEntitiesUrl("header", imageUrl);
      onSuccess();
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const uploadPropsFooter = {
    name: "imagedata",
    // multiple: true,
    fileList: defaultSectionUploadEntities["footer"],
    maxCount: 1,
    beforeUpload(file) {
      beforeUpload(file);
    },
    async customRequest(options) {
      const uploadData = await handleImageUpload(options);
      const [imagePath, imageUrl, onSuccess] = uploadData;
      handleDefaultTextChange("footer", imagePath);
      handleDefaultSectionUploadEntitiesUrl("footer", imageUrl);
      onSuccess();
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleCreateNewTemplateModalClick = () => {
    setShowCreateNewTemplateModal(true);
  };

  const menu = (
    <Menu onClick={handleCreateNewTemplateModalClick}>
      <Menu.Item key="create-new-template-modal">
        Save as new template
      </Menu.Item>
    </Menu>
  );

  // const handleCreateNewTemplateModalCancel = () => {
  //   setShowCreateNewTemplateModal(false);
  // };

  return (
    <>
      <>
        {!isCreateNewTemplate && (
          <Col span={24} style={{ marginTop: "-32px" }}>
            <Card title="Template name">
              {/* <Select
                defaultValue={templateData.name}
                style={{ width: "50%" }}
                // onChange={(e) => console.log(e)}
                disabled
              >
                <OptGroup label={templateData.category}>
                  <Option value={templateData._id}>{templateData.name}</Option>
                </OptGroup>
              </Select> */}
              <Input
                onChange={(e) => setTemplateNameText(e.target.value)}
                value={templateNameText}
                // disabled
                style={{ width: "50%" }}
              />
            </Card>
          </Col>
        )}
        <Col span={12}>
          <Card
            title="Default sections"
            extra={
              <Tooltip title="Apply to all instances of this template">
                <ExclamationCircleOutlined style={{ fontSize: "20px" }} />
              </Tooltip>
            }
          >
            <Row gutter={[16, 16]}>
              {Object.keys(templateData.default_sections).map((section, i) => {
                const entity = templateData.default_sections[section];
                return (
                  <div key={section} className="mr-4">
                    {/* <Col key={section} span={6}> */}
                    <Checkbox
                      name={entity["name"]}
                      defaultChecked={entity["selected"]}
                      key={entity["name"]}
                      disabled={!entity["available"]}
                      onChange={handleDefaultCheckboxStatusChange}
                    >
                      {defaultSectionEnums[entity["name"]]}
                    </Checkbox>
                    {/* </Col> */}
                  </div>
                );
              })}
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <Card
            style={{ minHeight: "165px" }}
            title="Interpretation sections"
            extra={
              <Tooltip
                title="To complete in interpretation tab"
                placement="topRight"
              >
                <ExclamationCircleOutlined style={{ fontSize: "20px" }} />
              </Tooltip>
            }
          >
            <Row gutter={[16, 16]}>
              {Object.keys(templateData.completion_sections).map(
                (section, i) => {
                  const entity = templateData.completion_sections[section];
                  return (
                    <div key={section} className="mr-4">
                      {/* <Col key={section} span={8}> */}
                      <Checkbox
                        name={entity["name"]}
                        defaultChecked={entity["selected"]}
                        key={entity["name"]}
                        // disabled={!entity["available"]}
                        disabled={true}
                        onChange={handleCompletionCheckboxStatusChange}
                      >
                        {completionSectionEnums[entity["name"]]}
                      </Checkbox>
                      {/* </Col> */}
                    </div>
                  );
                }
              )}
            </Row>
          </Card>
        </Col>

        {/* editors */}
        {defaultSectionEntities["logo"] &&
          defaultSectionEntities["logo"]["selected"] === true && (
            <Col span={24}>
              <Card title="Company logo image">
                <Dragger {...uploadPropsCompanyLogo}>
                  {/* <p className="ant-upload-drag-icon">
                    <FileImageOutlined />
                  </p> */}
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">Format: JPG or PNG</p>
                  <p className="ant-upload-hint ml-2">Size: 1200 x 200 px</p>
                </Dragger>
              </Card>
            </Col>
          )}

        {defaultSectionEntities["header"] &&
          defaultSectionEntities["header"]["selected"] === true && (
            <Col span={24}>
              <Card title="Header image">
                <Dragger {...uploadProps}>
                  {/* <p className="ant-upload-drag-icon">
                    <FileImageOutlined />
                  </p> */}
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">Format: JPG or PNG</p>
                  <p className="ant-upload-hint">Size: 1200 x 200 px</p>
                </Dragger>
              </Card>
            </Col>
          )}

        {defaultSectionEntities["sleep_center_info"] &&
          defaultSectionEntities["sleep_center_info"]["selected"] === true && (
            <Col span={24}>
              <Card title="Sleep center info">
                <div className="editor">
                  <CKEditor
                    name="sleep_center_info"
                    editor={ClassicEditor}
                    config={editorConfig}
                    data={defaultSectionEntities["sleep_center_info"]["text"]}
                    onReady={(editor) => {
                      handleOnReady(editor);
                    }}
                    onChange={(event, editor) => {
                      // console.log({ event, editor, data });
                      const data = editor.getData();
                      handleDefaultTextChange("sleep_center_info", data);
                    }}
                  />
                </div>
              </Card>
            </Col>
          )}

        {defaultSectionEntities["test_results"] &&
          defaultSectionEntities["test_results"]["selected"] === true && (
            <Col span={24}>
              <Card title="Test results">
                <div className="editor">
                  <CKEditor
                    editor={ClassicEditor}
                    config={editorConfig}
                    data={defaultSectionEntities["test_results"]["text"]}
                    onReady={(editor) => {
                      handleOnReady(editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      handleDefaultTextChange("test_results", data);
                    }}
                  />
                </div>
              </Card>
            </Col>
          )}

        {defaultSectionEntities["survey_results"] &&
          defaultSectionEntities["survey_results"]["selected"] === true && (
            <Col span={24}>
              <Card title="Survey results">
                <div className="editor">
                  <CKEditor
                    editor={ClassicEditor}
                    config={editorConfig}
                    data={defaultSectionEntities["survey_results"]["text"]}
                    onReady={(editor) => {
                      handleOnReady(editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      handleDefaultTextChange("survey_results", data);
                    }}
                  />
                </div>
              </Card>
            </Col>
          )}

        {defaultSectionEntities["physician_info"] &&
          defaultSectionEntities["physician_info"]["selected"] === true && (
            <Col span={24}>
              <Card title="Physician info">
                <div className="editor">
                  <CKEditor
                    editor={ClassicEditor}
                    config={editorConfig}
                    data={defaultSectionEntities["physician_info"]["text"]}
                    onReady={(editor) => {
                      handleOnReady(editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      handleDefaultTextChange("physician_info", data);
                    }}
                  />
                </div>
              </Card>
            </Col>
          )}

        {defaultSectionEntities["glossary"] &&
          defaultSectionEntities["glossary"]["selected"] === true && (
            <Col span={24}>
              <Card title="Glossary">
                <div className="editor">
                  <CKEditor
                    editor={ClassicEditor}
                    config={editorConfig}
                    data={defaultSectionEntities["glossary"]["text"]}
                    onReady={(editor) => {
                      handleOnReady(editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      handleDefaultTextChange("glossary", data);
                    }}
                  />
                </div>
              </Card>
            </Col>
          )}

        {defaultSectionEntities["intro_text"] &&
          defaultSectionEntities["intro_text"]["selected"] === true && (
            <Col span={24}>
              <Card title="Intro Text">
                <div className="editor">
                  <CKEditor
                    editor={ClassicEditor}
                    config={editorConfig}
                    data={defaultSectionEntities["intro_text"]["text"]}
                    onReady={(editor) => {
                      handleOnReady(editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      handleDefaultTextChange("intro_text", data);
                    }}
                  />
                </div>
              </Card>
            </Col>
          )}

        {defaultSectionEntities["footer"] &&
          defaultSectionEntities["footer"]["selected"] === true && (
            <Col span={24}>
              <Card title="Footer image">
                <Dragger {...uploadPropsFooter}>
                  {/* <p className="ant-upload-drag-icon">
                    <FileImageOutlined />
                  </p> */}
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">Format: JPG or PNG</p>
                  <p className="ant-upload-hint ml-2">Size: 1200 x 200 px</p>
                </Dragger>
              </Card>
            </Col>
          )}

        {false &&
          completionSectionEntities["findings"] &&
          completionSectionEntities["findings"]["selected"] === true && (
            <Col span={24}>
              <Card title="Findings">
                <div className="editor">
                  <CKEditor
                    editor={ClassicEditor}
                    config={editorConfig}
                    data={completionSectionEntities["findings"]["text"]}
                    onReady={(editor) => {
                      handleOnReady(editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      handleCompletionTextChange("findings", data);
                    }}
                  />
                </div>
              </Card>
            </Col>
          )}

        {false &&
          completionSectionEntities["diagnosis"] &&
          completionSectionEntities["diagnosis"]["selected"] === true && (
            <Col span={24}>
              <Card title="Diagnosis">
                <div className="editor">
                  <CKEditor
                    editor={ClassicEditor}
                    config={editorConfig}
                    data={completionSectionEntities["diagnosis"]["text"]}
                    onReady={(editor) => {
                      handleOnReady(editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      handleCompletionTextChange("diagnosis", data);
                    }}
                  />
                </div>
              </Card>
            </Col>
          )}

        {false &&
          completionSectionEntities["recommendations"] &&
          completionSectionEntities["recommendations"]["selected"] === true && (
            <Col span={24}>
              <Card title="Recommendations">
                <div className="editor">
                  <CKEditor
                    editor={ClassicEditor}
                    config={editorConfig}
                    data={completionSectionEntities["recommendations"]["text"]}
                    onReady={(editor) => {
                      handleOnReady(editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      handleCompletionTextChange("recommendations", data);
                    }}
                  />
                </div>
              </Card>
            </Col>
          )}
      </>

      <div className="ml-4">
        {/* <Button
          htmlType="button"
          style={{
            margin: "0 8px",
          }}
          // onClick={handleCancelClicked}
          onClick={handleSeePreview}
        >
          <EyeOutlined /> Preview
        </Button> */}

        {!isCreateNewTemplate ? (
          <Dropdown.Button
            onClick={handleSubmit}
            overlay={menu}
            trigger={["click"]}
            icon={<UpOutlined />}
            type="primary"
          >
            Save
          </Dropdown.Button>
        ) : (
          <Button type="primary" icon={<SaveOutlined />} onClick={handleSubmit}>
            Save
          </Button>
        )}
      </div>

      <CreateNewTemplateModal
        templateData={templateData}
        defaultSectionEntities={defaultSectionEntities}
        completionSectionEntities={completionSectionEntities}
        handleCreateNewTemplate={handleCreateNewTemplate}
        showCreateNewTemplateModal={showCreateNewTemplateModal}
        setShowCreateNewTemplateModal={setShowCreateNewTemplateModal}
      />
    </>
  );
};

export default TemplateEditors;
