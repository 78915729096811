import { api } from "api";
import {
  CATEGORIES_LIST_REQUEST,
  CATEGORIES_LIST_RESPONSE,
  TEMPLATES_LIST_REQUEST,
  TEMPLATES_LIST_RESPONSE,
  TEMPLATE_REQUEST,
  TEMPLATE_RESPONSE,
  EDIT_TEMPLATE_REQUEST,
  EDIT_TEMPLATE_RESPONSE,
  DELETE_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_RESPONSE,
  CREATE_TEMPLATE_REQUEST,
  CREATE_TEMPLATE_RESPONSE,
  CLEAR_TEMPLATE,
  MAKE_TEMPLATE_FROM_SHELL_REQUEST,
  MAKE_TEMPLATE_FROM_SHELL_RESPONSE,
  SNIPPETS_LIST_REQUEST,
  SNIPPETS_LIST_RESPONSE,
  SNIPPET_REQUEST,
  SNIPPET_RESPONSE,
  EDIT_SNIPPET_REQUEST,
  EDIT_SNIPPET_RESPONSE,
  DELETE_SNIPPET_REQUEST,
  DELETE_SNIPPET_RESPONSE,
  CREATE_SNIPPET_REQUEST,
  CREATE_SNIPPET_RESPONSE,
  VARIABLES_LIST_REQUEST,
  VARIABLES_LIST_RESPONSE,
  SIGNOFF_REQUEST,
  SIGNOFF_RESPONSE,
  PREVIEW_REQUEST,
  PREVIEW_RESPONSE,
  DRAFT_INTERPRETATION_REQUEST,
  DRAFT_INTERPRETATION_RESPONSE,
  CLEAR_DRAFT,
} from "./evaluation.types";

export const getCategoriesAction = (centre_id) => async (dispatch) => {
  try {
    dispatch(fetchCategories());
    const categories = await api.evaluation.getCategories(centre_id);
    dispatch(fetchCategoriesListResponse(null, categories));
  } catch (e) {
    console.warn(e);
    dispatch(fetchCategoriesListResponse(e));
  }
};

const fetchCategories = () => {
  return {
    type: CATEGORIES_LIST_REQUEST,
  };
};

const fetchCategoriesListResponse = (error, categories = null) => {
  return {
    type: CATEGORIES_LIST_RESPONSE,
    payload: {
      error,
      categories,
    },
  };
};

// template actions
export const getTemplatesAction = () => async (dispatch) => {
  try {
    dispatch(fetchTemplates());
    const templates = await api.evaluation.getTemplates();
    dispatch(fetchTemplatesListResponse(null, templates));
  } catch (e) {
    console.warn(e);
    dispatch(fetchTemplatesListResponse(e));
  }
};

const fetchTemplates = () => {
  return {
    type: TEMPLATES_LIST_REQUEST,
  };
};

const fetchTemplatesListResponse = (error, templates = null) => {
  return {
    type: TEMPLATES_LIST_RESPONSE,
    payload: {
      error,
      templates,
    },
  };
};

export const getTemplateAction = (id) => async (dispatch) => {
  try {
    dispatch(fetchTemplate());
    const template = await api.evaluation.getTemplate(id);
    dispatch(fetchTemplateResponse(null, template));
  } catch (e) {
    console.warn(e);
    dispatch(fetchTemplateResponse(e));
  }
};

const fetchTemplate = () => {
  return {
    type: TEMPLATE_REQUEST,
  };
};

const fetchTemplateResponse = (error, template = null) => {
  return {
    type: TEMPLATE_RESPONSE,
    payload: {
      error,
      template,
    },
  };
};

export const editTemplateAction = (_id, data) => async (dispatch) => {
  try {
    // dispatch(editTemplate());
    const template = await api.evaluation.editTemplate(_id, data);
    if (!template.error) {
      dispatch(editTemplateResponse(null, template.data));
    }
    return template;
  } catch (e) {
    console.warn(e);
    dispatch(editTemplateResponse(e));
  }
};

const editTemplate = () => {
  return {
    type: EDIT_TEMPLATE_REQUEST,
  };
};

const editTemplateResponse = (error, template = null) => {
  return {
    type: EDIT_TEMPLATE_RESPONSE,
    payload: {
      error,
      template,
    },
  };
};

export const deleteTemplateAction = (_id) => async (dispatch) => {
  try {
    dispatch(deleteTemplate());
    await api.evaluation.deleteTemplate(_id);
    dispatch(deleteTemplateResponse(null, _id));
  } catch (e) {
    console.warn(e);
    dispatch(deleteTemplateResponse(e));
  }
};

const deleteTemplate = () => {
  return {
    type: DELETE_TEMPLATE_REQUEST,
  };
};

const deleteTemplateResponse = (error, template_id = null) => {
  return {
    type: DELETE_TEMPLATE_RESPONSE,
    payload: {
      error,
      template_id,
    },
  };
};

export const createTemplateAction = (data) => async (dispatch) => {
  try {
    dispatch(createTemplate());
    const template = await api.evaluation.createTemplate(data);
    dispatch(createTemplateResponse(null, template));
    return template;
  } catch (e) {
    console.warn(e);
    dispatch(createTemplateResponse(e));
  }
};

const createTemplate = () => {
  return {
    type: CREATE_TEMPLATE_REQUEST,
  };
};

const createTemplateResponse = (error, template = null) => {
  return {
    type: CREATE_TEMPLATE_RESPONSE,
    payload: {
      error,
      template,
    },
  };
};

export const clearTemplateAction = () => async (dispatch) => {
  dispatch(clearTemplate());
};

const clearTemplate = () => {
  return {
    type: CLEAR_TEMPLATE,
  };
};

export const makeTemplateFromShellAction =
  (shellTemplateData) => async (dispatch) => {
    try {
      dispatch(makeTemplateFromShell());
      dispatch(makeTemplateFromShellResponse(null, shellTemplateData));
    } catch (e) {
      console.warn(e);
      dispatch(makeTemplateFromShellResponse(e));
    }
  };

const makeTemplateFromShell = () => {
  return {
    type: MAKE_TEMPLATE_FROM_SHELL_REQUEST,
  };
};

const makeTemplateFromShellResponse = (error, shellTemplateData = null) => {
  return {
    type: MAKE_TEMPLATE_FROM_SHELL_RESPONSE,
    payload: {
      error,
      shellTemplateData,
    },
  };
};

// snippets actions
export const getSnippetsAction = (type) => async (dispatch) => {
  try {
    dispatch(fetchSnippets());
    const snippets = await api.evaluation.getSnippets(type);
    dispatch(fetchSnippetsListResponse(null, snippets));
  } catch (e) {
    console.warn(e);
    dispatch(fetchSnippetsListResponse(e));
  }
};

const fetchSnippets = () => {
  return {
    type: SNIPPETS_LIST_REQUEST,
  };
};

const fetchSnippetsListResponse = (error, snippets = null) => {
  return {
    type: SNIPPETS_LIST_RESPONSE,
    payload: {
      error,
      snippets,
    },
  };
};

export const getSnippetAction = (id) => async (dispatch) => {
  try {
    dispatch(fetchSnippet());
    const snippet = await api.evaluation.getSnippet(id);
    dispatch(fetchSnippetResponse(null, snippet));
  } catch (e) {
    console.warn(e);
    dispatch(fetchSnippetResponse(e));
  }
};

const fetchSnippet = () => {
  return {
    type: SNIPPET_REQUEST,
  };
};

const fetchSnippetResponse = (error, snippet = null) => {
  return {
    type: SNIPPET_RESPONSE,
    payload: {
      error,
      snippet,
    },
  };
};

export const createSnippetAction = (data) => async (dispatch) => {
  try {
    dispatch(createSnippets());
    const snippet = await api.evaluation.createSnippet(data);
    dispatch(createSnippetsListResponse(null, snippet));
  } catch (e) {
    console.warn(e);
    dispatch(createSnippetsListResponse(e));
  }
};

const createSnippets = () => {
  return {
    type: CREATE_SNIPPET_REQUEST,
  };
};

const createSnippetsListResponse = (error, snippet = null) => {
  return {
    type: CREATE_SNIPPET_RESPONSE,
    payload: {
      error,
      snippet,
    },
  };
};

export const editSnippetAction = (_id, data) => async (dispatch) => {
  try {
    dispatch(editSnippet());
    const snippet = await api.evaluation.editSnippet(_id, data);
    dispatch(editSnippetResponse(null, snippet));
  } catch (e) {
    console.warn(e);
    dispatch(editSnippetResponse(e));
  }
};

const editSnippet = () => {
  return {
    type: EDIT_SNIPPET_REQUEST,
  };
};

const editSnippetResponse = (error, snippet = null) => {
  return {
    type: EDIT_SNIPPET_RESPONSE,
    payload: {
      error,
      snippet,
    },
  };
};

export const deleteSnippetAction = (_id) => async (dispatch) => {
  try {
    dispatch(deleteSnippet());
    await api.evaluation.deleteSnippet(_id);
    dispatch(deleteSnippetResponse(null, _id));
  } catch (e) {
    console.warn(e);
  }
};

const deleteSnippet = () => {
  return {
    type: DELETE_SNIPPET_REQUEST,
  };
};

const deleteSnippetResponse = (error, snippet_id = null) => {
  return {
    type: DELETE_SNIPPET_RESPONSE,
    payload: {
      error,
      snippet_id,
    },
  };
};

export const getVariablesAction = (test_id) => async (dispatch) => {
  try {
    dispatch(fetchVariables());
    const variables = await api.evaluation.getVariables(test_id);
    dispatch(fetchVariablesListResponse(null, variables));
  } catch (e) {
    console.warn(e);
    dispatch(fetchVariablesListResponse(e));
  }
};

const fetchVariables = () => {
  return {
    type: VARIABLES_LIST_REQUEST,
  };
};

const fetchVariablesListResponse = (error, variables = null) => {
  return {
    type: VARIABLES_LIST_RESPONSE,
    payload: {
      error,
      variables,
    },
  };
};

export const handleSignOffAction = (template_id, data) => async (dispatch) => {
  try {
    dispatch(handleSignOff());
    const signOff = await api.evaluation.processSignOff(template_id, data);
    dispatch(handleSignOffActionResponse(null, signOff.html));
    return signOff;
  } catch (e) {
    console.warn(e);
    dispatch(handleSignOffActionResponse(e));
  }
};

const handleSignOff = () => {
  return {
    type: SIGNOFF_REQUEST,
  };
};

const handleSignOffActionResponse = (error, signOff = null) => {
  return {
    type: SIGNOFF_RESPONSE,
    payload: {
      error,
      signOff,
    },
  };
};

export const handleSeePreviewAction =
  (template_id, data) => async (dispatch) => {
    try {
      dispatch(handleSeePreview());
      const preview = await api.evaluation.processPreview(template_id, data);
      dispatch(handleSeePreviewActionResponse(null, preview));
    } catch (e) {
      console.warn(e);
      dispatch(handleSeePreviewActionResponse(e));
    }
  };

const handleSeePreview = () => {
  return {
    type: PREVIEW_REQUEST,
  };
};

const handleSeePreviewActionResponse = (error, preview = null) => {
  return {
    type: PREVIEW_RESPONSE,
    payload: {
      error,
      preview,
    },
  };
};

export const draftInterpretationAction =
  (template_id, data) => async (dispatch) => {
    try {
      dispatch(draftInterpretation());
      // const template = await api.evaluation.getTemplate(id);
      dispatch(draftInterpretationResponse(null, { template_id, data }));
    } catch (e) {
      console.warn(e);
    }
  };

const draftInterpretation = () => {
  return {
    type: DRAFT_INTERPRETATION_REQUEST,
  };
};

const draftInterpretationResponse = (error, data = null) => {
  return {
    type: DRAFT_INTERPRETATION_RESPONSE,
    payload: {
      error,
      data,
    },
  };
};

export const clearDraftAction = () => async (dispatch) => {
  try {
    dispatch(clearDraft());
  } catch (e) {
    console.warn(e);
  }
};

const clearDraft = () => {
  return {
    type: CLEAR_DRAFT,
  };
};
