import { apiInstance } from "./axios";

export function getDocuments(test_id) {
  return apiInstance.get(`reports/documents/${test_id}`).then((res) => {
    let { nightly, ...rest } = res.data.documents;
    for (let i = 0; i < nightly.length; i++) {
      rest["night" + nightly[i]["night"]] = nightly[i];
    }
    return rest;
  });
}

export function getDocumentsZip(filePaths, test_id) {
  return apiInstance
    .post(`reports/documents/download`, {
      test: test_id,
      files: filePaths,
      responseType: "blob",
    })
    .then((response) => {
      function download(filename, data) {
        var element = document.createElement("a");
        element.setAttribute("href", "data:text/plain;base64," + data);
        element.setAttribute("download", filename);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
      download("Reports.zip", response.data.data);
    })
    .catch((e) => {
      throw e;
    });
}
