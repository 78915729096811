import { api } from "api";
import { TEST_REQUEST, TEST_RESPONSE } from "./tests.types";

export const getTest = (id) => async (dispatch) => {
  try {
    dispatch(fetchTest);
    const test = await api.tests.getTest(id);
    dispatch(fetchTestsListResponse(null, test));
  } catch (e) {
    console.warn(e);
    dispatch(fetchTestsListResponse(e));
  }
};

const fetchTest = () => {
  return {
    type: TEST_REQUEST,
  };
};

const fetchTestsListResponse = (error, test = null) => {
  return {
    type: TEST_RESPONSE,
    payload: {
      error,
      test,
    },
  };
};
