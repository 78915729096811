import { UnauthorizedError } from "core/util/errors";
import { setAuthToken, apiAccountsInstance } from "./axios";
import { userAuthObj } from "api/util.api";

export async function init() {
  try {
    const { token, service } = userAuthObj();
    setAuthToken(token, service);

    const userResponse = await validate();
    // localStorage.setItem(
    //   "session",
    //   JSON.stringify({
    //     user: userResponse._id,
    //     token,
    //     saved_at: new Date().toISOString(),
    //     service,
    //   })
    // );
    return {
      user: userResponse,
      type: "HCP DASHBOARD LOG IN",
    };
  } catch {
    throw new Error("No session token stored");
  }
}

export function validate(): Promise<UserAPI> {
  return apiAccountsInstance
    .get<{ user: UserAPI }>(`/users/me`)
    .then((result) => {
      return result.data.user;
    })
    .catch((error) => {
      setAuthToken(null, null);
      if (error.response && error.response.status === 401) {
        throw new UnauthorizedError();
      }
      return Promise.reject(error);
    });
}

export async function logout(): Promise<void> {
  setAuthToken(null);
  deleteCookie("x-auth-token");
  deleteCookie("x-signing-service");
  return;
  // return apiInstance.post('users/logout').then(() => {
  // });
}

function deleteCookie(cookie) {
  document.cookie = cookie + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
}
