export const data = {
  Ectopic_Night_1: 1,
  Beats_less_40_Night_1: 0,
  Beats_greater_100_Night_1: 0,
  Total_Recording_Time_Night_1: "00:06",
  Total_Sleep_Time_Night_1: "05:27",
  REM_Time_Night_1: 0.105625,
  T90_Night_1: 0,
  T88_Night_1: 0,
  T85_Night_1: 0,
  T80_Night_1: 0,
  AHI_Night_1: 0,
  AHI_Category_Night_1: "Healthy",
  ODI_3_Percent_Night_1: 0,
  ODI_4_Percent_Night_1: 0,
  Base_SPO2_Night_1: 98,
  Max_SPO2_Night_1: 100,
  Min_SPO2_Night_1: 95,
  Avg_SPO2_Night_1: 98,
  Max_PR_Night_1: 115,
  Min_PR_Night_1: 41,
  Avg_PR_Night_1: 57,
  Recording_Start_Night_1: "2020-11-21T00:07:11.000Z",
  Recording_End_Night_1: "2020-11-21T08:29:25.000Z",
  Rejected_Recording_Night_1: 24,
  SE_Night_1: 86,
  Ectopic_Night_2: 2,
  Beats_less_40_Night_2: 0,
  Beats_greater_100_Night_2: 0,
  Total_Recording_Time_Night_2: "00:25",
  Total_Sleep_Time_Night_2: "06:08",
  REM_Time_Night_2: 0.43041666666666667,
  T90_Night_2: 0,
  T88_Night_2: 0,
  T85_Night_2: 0,
  T80_Night_2: 0,
  AHI_Night_2: 0,
  AHI_Category_Night_2: "Healthy",
  ODI_3_Percent_Night_2: 0,
  ODI_4_Percent_Night_2: 0,
  Base_SPO2_Night_2: 98,
  Max_SPO2_Night_2: 100,
  Min_SPO2_Night_2: 94,
  Avg_SPO2_Night_2: 98,
  Max_PR_Night_2: 117,
  Min_PR_Night_2: 44,
  Avg_PR_Night_2: 62,
  Recording_Start_Night_2: "2020-11-22T00:47:31.000Z",
  Recording_End_Night_2: "2020-11-22T09:00:01.000Z",
  Rejected_Recording_Night_2: 13,
  SE_Night_2: 86,
  Ectopic_Night_3: 1,
  Beats_less_40_Night_3: 0,
  Beats_greater_100_Night_3: 0,
  Total_Recording_Time_Night_3: "00:03",
  Total_Sleep_Time_Night_3: "06:40",
  REM_Time_Night_3: 0.057916666666666665,
  T90_Night_3: 0,
  T88_Night_3: 0,
  T85_Night_3: 0,
  T80_Night_3: 0,
  AHI_Night_3: 0,
  AHI_Category_Night_3: "Healthy",
  ODI_3_Percent_Night_3: 0,
  ODI_4_Percent_Night_3: 0,
  Base_SPO2_Night_3: 97,
  Max_SPO2_Night_3: 100,
  Min_SPO2_Night_3: 95,
  Avg_SPO2_Night_3: 98,
  Max_PR_Night_3: 118,
  Min_PR_Night_3: 42,
  Avg_PR_Night_3: 62,
  Recording_Start_Night_3: "2020-11-22T23:39:33.000Z",
  Recording_End_Night_3: "2020-11-23T07:29:24.000Z",
  Rejected_Recording_Night_3: 5,
  SE_Night_3: 90,
  Avg_Total_Sleep_Time: "06:05",
  Avg_Total_Recording_Time: "00:11",
  Avg_Sleep_Efficiency: 87,
  Max_AHI: 0,
  Min_AHI: 0,
  Avg_AHI: 0,
  Max_ODI_3: 0,
  Max_ODI_4: 0,
  Min_ODI_3: 0,
  Min_ODI_4: 0,
  Avg_ODI_3: 0,
  Avg_ODI_4: 0,
  Avg_T90: 0,
  Min_SPO2: 94,
  Max_SPO2: 100,
  Avg_SPO2: 98,
  Base_SPO2: 98,
  Total_Nights: 3,
  Failed_Nights: 0,
  Mean_Reject: 14,
  Mean_PR: 60,
  Beats_greater_100: 0,
  Beats_less_40: 0,
  Ectopic: 1,
  Max_Severity: "Healthy",
  Median_Severity: "Not Available",
  Mean_Severity: "Healthy",
  Max_TST: "06:40",
  Min_TST: "05:27",
  Mean_TST: "06:05",
  Max_TRT: "00:25",
  Min_TRT: "00:03",
  Mean_TRT: "00:11",
  Max_SE: 90,
  Mean_SE: 87,
  nightlyImages: [
    {
      night: 3,
      spo2: "https://ectosense-hcp-qa-bucket.s3.eu-west-3.amazonaws.com/high-charts/44e18337-4fb1-4b3f-93b1-aa62c69fabc5.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASZ4EAGY6YOZO7LU4%2F20211021%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20211021T095845Z&X-Amz-Expires=3600&X-Amz-Signature=a8efa7e6991eede75fd049d5e951ca2504f39f6df7cc97bc42e51e107a6e5bda&X-Amz-SignedHeaders=host",
      ppg: "https://ectosense-hcp-qa-bucket.s3.eu-west-3.amazonaws.com/high-charts/03ec2fe4-fe01-4f49-a735-2bdfc24d9009.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASZ4EAGY6YOZO7LU4%2F20211021%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20211021T095845Z&X-Amz-Expires=3600&X-Amz-Signature=f0e0481903f0710f3a8955053ffa1de5bf95e6b7c961e52cacc806bc8fad0f3b&X-Amz-SignedHeaders=host",
      pr: "https://ectosense-hcp-qa-bucket.s3.eu-west-3.amazonaws.com/high-charts/84ced1b4-4687-4074-8586-28da1c09bb11.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASZ4EAGY6YOZO7LU4%2F20211021%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20211021T095845Z&X-Amz-Expires=3600&X-Amz-Signature=61046bd0b943d6839e758cdef1f5b7df6b40d7cf61f3e301a69ac656b5fe6349&X-Amz-SignedHeaders=host",
      activity:
        "https://ectosense-hcp-qa-bucket.s3.eu-west-3.amazonaws.com/high-charts/d881629e-6653-410d-be05-05ad215a128d.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASZ4EAGY6YOZO7LU4%2F20211021%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20211021T095846Z&X-Amz-Expires=3600&X-Amz-Signature=4d353b4e491d0ced9b92deb85b1c079adaa9ae43d8cbda8df02ee507f15114cd&X-Amz-SignedHeaders=host",
      poincare:
        "https://ectosense-hcp-qa-bucket.s3.eu-west-3.amazonaws.com/high-charts/690a1117-1954-4163-a726-8c028732fcc0.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASZ4EAGY6YOZO7LU4%2F20211021%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20211021T095846Z&X-Amz-Expires=3600&X-Amz-Signature=99e9b2acb4b71db36ecf8269707922197ddfade249f184faffd040929b49edea&X-Amz-SignedHeaders=host",
    },
    {
      night: 1,
      spo2: "https://ectosense-hcp-qa-bucket.s3.eu-west-3.amazonaws.com/high-charts/8b1efde2-163a-4afe-9cf8-451380853394.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASZ4EAGY6YOZO7LU4%2F20211021%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20211021T095845Z&X-Amz-Expires=3600&X-Amz-Signature=ff2122bc1e9b365fcc3cbe43b176e1d3f519b9a7f9c2d4d5dd12171cd616a8be&X-Amz-SignedHeaders=host",
      ppg: "https://ectosense-hcp-qa-bucket.s3.eu-west-3.amazonaws.com/high-charts/0173d52f-ba29-4c3c-9e60-7a85f716f08c.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASZ4EAGY6YOZO7LU4%2F20211021%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20211021T095845Z&X-Amz-Expires=3600&X-Amz-Signature=3e174896fa87940519f32c4f75bccdcec777e6060c6db8b67473e05d4aed580c&X-Amz-SignedHeaders=host",
      pr: "https://ectosense-hcp-qa-bucket.s3.eu-west-3.amazonaws.com/high-charts/dbfc29f6-059c-4d5a-b935-7068fcb66a27.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASZ4EAGY6YOZO7LU4%2F20211021%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20211021T095846Z&X-Amz-Expires=3600&X-Amz-Signature=09496beb4c251adbb5996e9ccbaabbd868c96673420491ec7a4178caf5a9e88a&X-Amz-SignedHeaders=host",
      activity:
        "https://ectosense-hcp-qa-bucket.s3.eu-west-3.amazonaws.com/high-charts/555ee14a-4b70-4f0d-9d28-05f871c7f202.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASZ4EAGY6YOZO7LU4%2F20211021%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20211021T095846Z&X-Amz-Expires=3600&X-Amz-Signature=46d6567356fcd7481865efe76314f8073a936d2bec2d7563b3b0e42719ad90c3&X-Amz-SignedHeaders=host",
      poincare:
        "https://ectosense-hcp-qa-bucket.s3.eu-west-3.amazonaws.com/high-charts/46c71038-4704-4039-9973-32496aba64f1.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASZ4EAGY6YOZO7LU4%2F20211021%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20211021T095846Z&X-Amz-Expires=3600&X-Amz-Signature=52f6a65a2228a9ce6ebf179b8ee0c5a490a551ebe23c32516cc3adef0467810d&X-Amz-SignedHeaders=host",
    },
    {
      night: 2,
      spo2: "https://ectosense-hcp-qa-bucket.s3.eu-west-3.amazonaws.com/high-charts/b2c4a2f0-9190-4b8e-9aa7-cf1b55e2efa1.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASZ4EAGY6YOZO7LU4%2F20211021%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20211021T095845Z&X-Amz-Expires=3600&X-Amz-Signature=2412c86a0b525aa4ced4745c6fde297344238e6034e41f9c78dc7fb4f6a18a42&X-Amz-SignedHeaders=host",
      ppg: "https://ectosense-hcp-qa-bucket.s3.eu-west-3.amazonaws.com/high-charts/8109f2b9-26d7-4875-bca1-fef7eeb6d129.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASZ4EAGY6YOZO7LU4%2F20211021%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20211021T095845Z&X-Amz-Expires=3600&X-Amz-Signature=d57acd9f8635693f38e2e0b58ce0775ff0bbf9052257265a13fd93e81c4e05c0&X-Amz-SignedHeaders=host",
      pr: "https://ectosense-hcp-qa-bucket.s3.eu-west-3.amazonaws.com/high-charts/f7d058f1-33d1-47f9-9ea2-6191f361f31d.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASZ4EAGY6YOZO7LU4%2F20211021%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20211021T095846Z&X-Amz-Expires=3600&X-Amz-Signature=516cf6647f08dc3bcceac8e142305cc619abd5dfeb923f5808a5b852e4bdb0f6&X-Amz-SignedHeaders=host",
      activity:
        "https://ectosense-hcp-qa-bucket.s3.eu-west-3.amazonaws.com/high-charts/39dcd889-8146-47a8-849a-c53630e00600.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASZ4EAGY6YOZO7LU4%2F20211021%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20211021T095846Z&X-Amz-Expires=3600&X-Amz-Signature=9805d895a0ff78b64decb220385e8cb5835bb5656cdb39ddcc0f76f2ca26d6e6&X-Amz-SignedHeaders=host",
      poincare:
        "https://ectosense-hcp-qa-bucket.s3.eu-west-3.amazonaws.com/high-charts/04eedbe9-cc79-45b0-9872-91ae9fc73157.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASZ4EAGY6YOZO7LU4%2F20211021%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20211021T095846Z&X-Amz-Expires=3600&X-Amz-Signature=f7db6269e0d982aa1c438110a3c8bd3edc394d0b98b109b434a066cb6fa116b0&X-Amz-SignedHeaders=host",
    },
  ],
  summaryImages: {
    ahi: "https://ectosense-hcp-qa-bucket.s3.eu-west-3.amazonaws.com/high-charts/626597f0-7c63-461b-9aea-e111407ce8cc.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASZ4EAGY6YOZO7LU4%2F20211021%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20211021T095845Z&X-Amz-Expires=3600&X-Amz-Signature=4bf75a483c639d2af64677b5aa6f9d27cd21a71856a0db00dc2466d81c38987f&X-Amz-SignedHeaders=host",
    spo2: "https://ectosense-hcp-qa-bucket.s3.eu-west-3.amazonaws.com/high-charts/47b5b0ba-edf7-4e7c-8ad6-d8851a0aa956.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASZ4EAGY6YOZO7LU4%2F20211021%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20211021T095845Z&X-Amz-Expires=3600&X-Amz-Signature=c023e3e4edee7d23516e43aed1eeba350eaadeccc4ad965a7840bb39fa4b8a94&X-Amz-SignedHeaders=host",
    tst: "https://ectosense-hcp-qa-bucket.s3.eu-west-3.amazonaws.com/high-charts/e928777a-2431-4922-ab00-ba1167584a0f.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASZ4EAGY6YOZO7LU4%2F20211021%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20211021T095845Z&X-Amz-Expires=3600&X-Amz-Signature=41a065f775dfb4929b1e448a297368a740b33b3d4aefa1aa6edcf04a0471ca75&X-Amz-SignedHeaders=host",
  },
  First_Name: "CAPA",
  Last_Name: "NIGHT416",
  Dob: "2020-11-15T23:06:12.000Z",
  Email: "wout.geeurickx@ectosense.com",
  Phone: null,
};
