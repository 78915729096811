import { apiInstance } from "./axios";
import {
  convertTest,
  convertTestReport,
  convertRecordingReport,
} from "./transformers/testTransformer";
import { compact } from "lodash";

export function getTests(units: string[], updatedSince?: Date) {
  return apiInstance
    .get("tests", {
      params: {
        with_related: true,
        query: {
          // unit: { $in: units },
          deleted_at: null,
          modified_at: updatedSince ? { $gt: updatedSince } : undefined,
        },
        // page: 1,
        // page_size: 1,
      },
    })
    .then((res) => {
      return res.data.tests.map(convertTest);
    });
}

export function getTest(id: string) {
  return apiInstance
    .get(`tests/${id}`, {
      params: {
        with_related: true,
      },
    })
    .then((res) => {
      return res.data.test;
    })
    .catch((err) => ({
      error: true,
    }));
}

export function fetchTestReports(query, sort, page, page_size) {
  return apiInstance
    .get("reports/summary", {
      params: {
        query,
        page,
        page_size,
        sort,
      },
    })
    .then((res) => {
      return compact(res.data.reports.map(convertTestReport));
    });
}

export function fetchRecordingReports(query, sort, page, page_size) {
  return apiInstance
    .get("reports/pdf", {
      params: {
        query,
        page,
        page_size,
        sort,
      },
    })
    .then((res) => {
      return res.data.reports.map(convertRecordingReport);
    });
}

export async function fetchReports(tests, recIds) {
  const recordingIds = recIds;

  const recordingMap = recordingIds.reduce((acc, id) => {
    acc[id] = {};
    return acc;
  }, {});

  const summaryReport = await fetchTestReports(
    {
      test: tests.map((test) => test.id),
      approved: true,
      current_report: true,
    },
    undefined,
    0,
    tests.map((test) => test.id).length
  );

  const nightReports = await fetchRecordingReports(
    { recording: recordingIds, approved: true, current_report: true },
    undefined,
    0,
    recordingIds.length
  );

  // summaryReport.forEach(report => {
  //   const test = self.tests.get(report.test);
  //   test?.setReport(report);
  // });

  nightReports.forEach((report) => {
    recordingMap[report.recording] = report;
  });
  return recordingMap;
}
