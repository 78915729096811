import { apiInstance } from "./axios";
import { composeEventType } from "containers/EventLog/util";
// import { eventsObj } from "containers/EventLog/constants";

export function getEvents(id: string) {
  return apiInstance
    .get(`eventlogs?test=${id}`)
    .then((res) => {
      return composeEventType(res.data.events);
      // return res.data.events;
    })
    .catch((err) => ({
      error: true,
    }));
}
