import { api } from "api";

import {
  DOCUMENTS_LIST_REQUEST,
  DOCUMENTS_LIST_RESPONSE,
  DOCUMENTS_ZIP_REQUEST,
  DOCUMENTS_ZIP_RESPONSE,
} from "./documents.types";

export const getDocumentsAction = (test_id) => async (dispatch) => {
  try {
    dispatch(fetchDocuments());
    const documents = await api.documents.getDocuments(test_id);
    dispatch(fetchDocumentsListResponse(null, documents));
  } catch (e) {
    console.warn(e);
    dispatch(fetchDocumentsListResponse(e));
  }
};

const fetchDocuments = () => {
  return {
    type: DOCUMENTS_LIST_REQUEST,
  };
};

const fetchDocumentsListResponse = (error, documents = null) => {
  return {
    type: DOCUMENTS_LIST_RESPONSE,
    payload: {
      error,
      documents,
    },
  };
};

export const getDocumentsZipAction =
  (filePaths, test_id) => async (dispatch) => {
    try {
      dispatch(fetchDocumentsZip());
      const documents = await api.documents.getDocumentsZip(filePaths, test_id);
      dispatch(fetchDocumentsZipListResponse(null, documents));
    } catch (e) {
      console.warn(e);
      dispatch(fetchDocumentsZipListResponse(e));
    }
  };

const fetchDocumentsZip = () => {
  return {
    type: DOCUMENTS_ZIP_REQUEST,
  };
};

const fetchDocumentsZipListResponse = (error, documents = null) => {
  return {
    type: DOCUMENTS_ZIP_RESPONSE,
    payload: {
      error,
      documents,
    },
  };
};
