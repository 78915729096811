import { api } from "api";

import { UPDATE_NOTES_REQUEST, UPDATE_NOTES_RESPONSE } from "./notes.types";
import { UPDATE_TEST_RESPONSE } from "../tests/tests.types";

export const updateNotesAction = (testObj, data) => async (dispatch) => {
  try {
    dispatch(fetchNotes());
    const test = await api.notes.updateNotes(testObj._id, data);
    dispatch(fetchNotesResponse(null, test));

    // call test reducer to update test object
    dispatch(updateTestResponse(null, test));
  } catch (e) {
    console.warn(e);
    dispatch(fetchNotesResponse(e));
  }
};
const fetchNotes = () => {
  return {
    type: UPDATE_NOTES_REQUEST,
  };
};
const fetchNotesResponse = (error, test = null) => {
  return {
    type: UPDATE_NOTES_RESPONSE,
    payload: {
      error,
      test,
    },
  };
};

// call test reducer to update test object
const updateTestResponse = (error, test = null) => {
  return {
    type: UPDATE_TEST_RESPONSE,
    payload: {
      error,
      test,
    },
  };
};
