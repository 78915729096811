import { ipApiInstance } from "./axios";

export function getCategories(centre_id) {
  return ipApiInstance
    .get(`templatecategories?centre_id=${centre_id}`)
    .then((res) => {
      return res.data.category;
    });
}

export function getTemplates() {
  return ipApiInstance.get(`templates`).then((res) => {
    return res.data.templates;
  });
}

export function getTemplate(id: string) {
  return ipApiInstance.get(`templates/${id}`).then((res) => {
    return res.data.template;
  });
}

export function editTemplate(_id, data) {
  return ipApiInstance
    .patch(`templates/${_id}`, data)
    .then((res) => {
      return { error: false, data: res.data.template };
    })
    .catch((e) => {
      return { error: true, data: null };
    });
}

export function deleteTemplate(id) {
  return ipApiInstance.delete(`templates/${id}`).then((res) => {
    return res.data.message;
  });
}

export function createTemplate(data) {
  return ipApiInstance.post(`templates`, { ...data }).then((res) => {
    return res.data.template;
  });
}

export const getSnippets = (type) => {
  return ipApiInstance.get(`snippets?type=${type}`).then((res) => {
    return res.data.snippets;
  });
};

export function getSnippet(id: string) {
  return ipApiInstance.get(`snippets/${id}`).then((res) => {
    return res.data.snippet;
  });
}

export const createSnippet = (data) => {
  return ipApiInstance.post(`snippets`, { ...data }).then((res) => {
    return res.data.snippet;
  });
};

export function editSnippet(_id, data) {
  return ipApiInstance.patch(`snippets/${_id}`, data).then((res) => {
    return res.data.snippet;
  });
}

export function deleteSnippet(id) {
  return ipApiInstance.delete(`snippets/${id}`).then((res) => {
    return res.data.message;
  });
}

export function handleImageUpload(options) {
  const { file, onSuccess } = options;
  const f = new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      return resolve(e.target.result);
    };
  });
  return f.then((data) => {
    return ipApiInstance
      .post(`templates/uploadimage`, {
        imagedata: data,
      })
      .then((res) => {
        return [res.data.filename, res.data.url, onSuccess];
      });
  });
}

export function getVariables(test_id) {
  return ipApiInstance
    .get(`templates/report/variables?test=${test_id}`)
    .then((res) => {
      const data = res.data.variables;

      let convertedVariables = {
        convertedTemplatingVariables: [],
        convertedInterpretingVariables: [],
      };

      for (let i in data) {
        if (i === "nightlyImages" || i === "summaryImages" || !data[i]) {
          continue;
        }
        let templatinObject = {};
        let interpretingObject = {};

        templatinObject["id"] = `@#${i}#@`;
        convertedVariables.convertedTemplatingVariables.push(templatinObject);

        interpretingObject["id"] = `@#${i}#@`;
        interpretingObject["text"] = data[i].toString();
        convertedVariables.convertedInterpretingVariables.push(
          interpretingObject
        );
      }

      let originalVariables = {};

      for (let i in data) {
        if (i === "nightlyImages" || i === "summaryImages" || !data[i]) {
          continue;
        }
        originalVariables[i] = data[i].toString();
      }
      return [convertedVariables, originalVariables, res.data.variables];
    });
}

export const processSignOff = (_id, data) => {
  const { test, ...sections } = data;
  return ipApiInstance
    .post(`templates/process/${_id}`, { sections, test })
    .then((res) => {
      return { error: false, html: res.data.html };
    });
};

export const processPreview = (_id, data) => {
  return ipApiInstance
    .post(`templates/preview/${_id}`, { sections: data })
    .then((res) => {
      return res.data.html;
    });
};
