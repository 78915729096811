export const uninitialized = "uninitialized";
export const initialized = "initialized";
export const success = "success";
export const error = "error";

export const DOCUMENTS_LIST_REQUEST = "DOCUMENTS_LIST_REQUEST";
export const DOCUMENTS_LIST_RESPONSE = "DOCUMENTS_LIST_RESPONSE";

export const DOCUMENTS_ZIP_REQUEST = "DOCUMENTS_ZIP_REQUEST";
export const DOCUMENTS_ZIP_RESPONSE = "DOCUMENTS_ZIP_RESPONSE";
