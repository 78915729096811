import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Interpretation from "./views/Interpretation";
import Prescription from "./views/Prescription";

function Evaluation() {
  const renderEvaluationRoute = () => {
    return (
      <Redirect
        to={{
          pathname: "/study/evaluation/interpretation",
        }}
      />
    );
  };

  return (
    <Switch>
      <Route
        exact
        path="/study/evaluation/interpretation"
        component={Interpretation}
      />
      <Route
        exact
        path="/study/evaluation/prescription"
        component={Prescription}
      />
      <Route exact path="/study/evaluation" render={renderEvaluationRoute} />
    </Switch>
  );
}

export default Evaluation;
