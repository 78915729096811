import React, { useState, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// import { RouteProps, RouterProps } from "react-router";

// import Surveys from "containers/Surveys";
import EventLog from "containers/EventLog";
import TestResults from "containers/TestResults";
import Evaluation from "containers/Evaluation";
import Documents from "containers/Documents";
import Empty from "containers/Empty";
import { Layout, Menu } from "antd";
import {
  LineChartOutlined,
  NodeIndexOutlined,
  TeamOutlined,
  FileOutlined,
  FileSearchOutlined,
  EditOutlined,
  SettingOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  FormOutlined,
} from "@ant-design/icons";
import Interpretation from "containers/Evaluation/views/Interpretation";
import Prescription from "containers/Evaluation/views/Prescription";
import Summary from "containers/TestResults/views/Summary";

import LogoSmall from "svgs/logo_small.svg";
import "./styles.scss";
import NightTab from "containers/TestResults/views/NightTab";
import Notes from "containers/Notes";

const { SubMenu } = Menu;
const { Content, Sider } = Layout;

const Study = (props) => {
  const { sidebarProps, test } = props;
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const redirectTo = () => {
    return (
      <Redirect
        to={{
          pathname: "/study/summary",
        }}
      />
    );
  };

  const renderNightTabs = () => {
    if (sidebarProps.testResults.totalNights) {
      let tabs = [];
      for (let i = 1; i <= sidebarProps.testResults.totalNights; i++) {
        tabs.push(
          <Route
            key={i}
            exact
            path={`/study/night-${i}`}
            render={() => (
              <NightTab
                test={test}
                testResults={sidebarProps.testResults}
                night={i}
              />
            )}
          />
        );
      }
      return tabs;
    }
    return null;
  };

  const renderNightMenus = () => {
    if (sidebarProps.testResults.totalNights) {
      let menus = [];
      for (let i = 1; i <= sidebarProps.testResults.totalNights; i++) {
        menus.push(<Menu.Item key={`night-${i}`}>{`Night ${i}`}</Menu.Item>);
      }
      return menus;
    }
    return null;
  };

  return (
    <Layout>
      <Layout>
        <Sider
          style={{
            overflow: "hidden",
            height: "100vh",
          }}
          width={200}
          className="site-layout-background"
          collapsed={collapsed}
          onCollapse={onCollapse}
        >
          <div className="logo">
            <img src={LogoSmall} className="" alt="logo" />
          </div>
          <Menu
            mode="inline"
            theme={"light"}
            defaultOpenKeys={["test-results"]}
            defaultSelectedKeys={["summary"]}
            // defaultOpenKeys={["evaluation"]}
            // defaultSelectedKeys={["interpretation"]}
            style={{ height: "100%", borderRight: 0 }}
            onClick={(e) => {
              console.log(`/study/${e.key}`);
              return props.history.push(`/study/${e.key}`);
            }}
          >
            <SubMenu
              key="test-results"
              icon={<LineChartOutlined />}
              title="Test results"
            >
              <Menu.Item key="summary">Summary</Menu.Item>
              {renderNightMenus()}
            </SubMenu>
            <Menu.Item key="surveys" icon={<FormOutlined />}>
              Surveys
            </Menu.Item>
            <SubMenu
              key="evaluation"
              icon={<FileSearchOutlined />}
              title="Evaluation"
            >
              <Menu.Item key="interpretation">Interpretation</Menu.Item>
              <Menu.Item key="prescription">Prescription</Menu.Item>
            </SubMenu>
            <Menu.Item key="collaboration" icon={<TeamOutlined />}>
              Collaboration
            </Menu.Item>
            <Menu.Item key="event-log" icon={<NodeIndexOutlined />}>
              Event log
            </Menu.Item>
            <Menu.Item key="notes" icon={<EditOutlined />}>
              Notes
            </Menu.Item>
            <Menu.Item key="test-settings" icon={<SettingOutlined />}>
              Test settings
            </Menu.Item>
            <Menu.Item key="documents" icon={<FileOutlined />}>
              Documents
            </Menu.Item>
          </Menu>
        </Sider>
        {!collapsed ? (
          <div className="sider-trigger" onClick={onCollapse}>
            <span>
              <MenuFoldOutlined />
            </span>
          </div>
        ) : (
          <div className="sider-trigger" onClick={onCollapse}>
            <span>
              <MenuUnfoldOutlined />
            </span>
          </div>
        )}
        <Layout>
          <Content
            // className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            <Suspense fallback={<div>loading...</div>}>
              <Switch>
                <Route
                  exact
                  path="/study/test-results"
                  render={(props) => <TestResults {...props} />}
                />
                <Route
                  exact
                  path="/study/summary"
                  render={(props) => (
                    <Summary
                      test={test}
                      testResults={sidebarProps.testResults}
                      {...props}
                    />
                  )}
                />
                {renderNightTabs()}
                <Route exact path="/study/surveys" component={Empty} />
                <Route exact path="/study/evaluation" component={Evaluation} />
                <Route
                  exact
                  path="/study/interpretation"
                  component={Interpretation}
                />
                <Route
                  exact
                  path="/study/prescription"
                  component={Prescription}
                />
                <Route exact path="/study/collaboration" component={Empty} />
                <Route
                  exact
                  path="/study/event-log"
                  render={(props) => <EventLog {...props} />}
                />
                <Route exact path="/study/notes" component={Notes} />
                <Route exact path="/study/test-settings" component={Empty} />
                <Route exact path="/study/documents" component={Documents} />
                <Route exact path="/study" render={redirectTo} />
              </Switch>
            </Suspense>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Study;
