import React, { useState } from "react";
import { Row, Col, Card, Input, Select } from "antd";

const { Option, OptGroup } = Select;

const CreateNewTemplate = (props) => {
  const { templatesData, handleMakeTemplateFromShell } = props;
  const [templateName, setTemplateName] = useState("");

  const handleSelect = (template_id) => {
    let template;

    Object.keys(templatesData).map((t, i) => {
      template = templatesData[t].find((temp) => temp._id === template_id);
    });

    const { _id, centre, category, default_sections, completion_sections } =
      template;

    let shellTemplate = {
      name: templateName,
      centre,
      parent_template: _id,
      category,
      default_sections,
      completion_sections,
    };

    handleMakeTemplateFromShell(shellTemplate);
  };
  return (
    <>
      <Col span={24} style={{ marginTop: "-32px" }}>
        <Card>
          <Row gutter={[32, 32]}>
            <Col span={12}>
              <div className="mb-2">Template name</div>
              <Input
                onChange={(e) => setTemplateName(e.target.value)}
                placeholder="Name your new template"
              />
            </Col>
            <Col span={12}>
              <div className="mb-2">(Base) Parent template </div>
              <Select
                style={{ width: "100%" }}
                placeholder="Choose an existing template to use as a starting point"
                disabled={!templateName}
                onSelect={handleSelect}
              >
                {Object.keys(templatesData).map((t, i) => (
                  <OptGroup key={i} label={t}>
                    {templatesData[t].map((c) => (
                      <Option key={c._id} value={c._id}>
                        <div>{c.name} </div>
                      </Option>
                    ))}
                  </OptGroup>
                ))}
              </Select>
            </Col>
          </Row>
        </Card>
      </Col>
    </>
  );
};

export default CreateNewTemplate;
