import React, { useState } from "react";
import { Row, Col, Divider, Input, Button, Space, Modal, message } from "antd";

import renderHTML from "react-render-html";

import AddSnippetDrawer from "./AddSnippetDrawer";
import EditSnippetDrawer from "./EditSnippetDrawer";

import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import "../../styles.scss";

const { Search } = Input;
const { confirm } = Modal;

const SnippetsDrawer = (props) => {
  const { snippet, snippetsData, variablesData, setShowSnippetDrawer } = props;
  // const [editorVariables, originalVariables] = variablesData;
  const snippetData = snippet.data;

  const [isSnippetList, setIsSnippetList] = useState(true);
  const [isAddDrawer, setIsAddDrawer] = useState(false);
  const [isEditDrawer, setIsEditDrawer] = useState(false);
  const [snippets, setSnippets] = useState(snippetsData);
  const [editorContent, setEditorContent] = useState("");

  const onChangeCodeSnippet = (e) => {
    const keyword = e.target.value;
    setSnippets(() =>
      snippetsData.filter((item) =>
        item.name.toLowerCase().includes(keyword.toLowerCase())
      )
    );
  };

  const handleEditorContentChange = (data) => {
    setEditorContent(data);
  };

  const handleGetSnippet = (e, id) => {
    e.stopPropagation();
    props.handleGetSnippet(id);
    setIsEditDrawer(true);
  };

  const handleCreateSubmit = (values) => {
    if (editorContent === "") {
      message.error("Snippet text cannot be left empty");
      return;
    }
    let data = { ...values, content: editorContent };
    props.handleCreateSnippet(data);
    setShowSnippetDrawer(false);
  };

  const handleEditSubmit = (values) => {
    props.handleSnippetEditClicked(snippetData._id, {
      ...values,
      content: editorContent,
    });
  };

  const handleSnippetInjection = (content) => {
    props.handleSnippetInjection(content);
  };

  const handleDeleteConfirm = (e, c) => {
    e.stopPropagation();
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete ${c.name}`,
      okText: "Delete",

      cancelText: "Cancel",
      onOk() {
        props.handleSnippetDeleteClicked(e, c._id);
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };

  return (
    <>
      {isSnippetList ? (
        <div className="drawer">
          <div className="snippet-list-section">
            <div className="header">
              <div className="components-container">
                <Row justify="center" align="middle">
                  <Col span={3}>
                    <div className="text_title">Snippets</div>
                  </Col>
                  <Col offset={1} span={16}>
                    <Search
                      placeholder="Search snippet"
                      onChange={onChangeCodeSnippet}
                    />
                  </Col>
                  <Col offset={1} span={3}>
                    <Button
                      style={{ backgroundColor: "#83c8bc", color: "#fff" }}
                      type="default"
                      onClick={() => {
                        setIsSnippetList(false);
                        setIsAddDrawer(true);
                      }}
                      icon={<PlusOutlined />}
                    />
                  </Col>
                </Row>
              </div>
            </div>
            <Divider className="divider" />

            <div className="body">
              <div className="list-container">
                <Row gutter={[32, 32]}>
                  {snippets.map((snippet, i) => {
                    return (
                      <Col key={i} span={24}>
                        <div className="list-header d-flex justify-content-space-between">
                          <div className="text_title">{snippet.name}</div>
                          <div className="extra">
                            <Space size="middle">
                              <EditOutlined
                                className="icon"
                                onClick={(e) =>
                                  handleGetSnippet(e, snippet._id)
                                }
                              />
                              <DeleteOutlined
                                className="icon"
                                onClick={(e) => handleDeleteConfirm(e, snippet)}
                              />
                            </Space>
                          </div>
                        </div>
                        <div
                          className="list-body"
                          onClick={() =>
                            handleSnippetInjection(snippet.content)
                          }
                        >
                          <div className="list">
                            {/* {console.log("snippet", snippet)} */}
                            {/* parserHelper(
                                snippet.content,
                                originalVariables,
                                true
                              ) */}
                            {renderHTML(snippet.content)}
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>
          </div>
          <div className="bottom-right-fixed">
            <Button
              htmlType="button"
              onClick={() => {
                setShowSnippetDrawer(false);
              }}
            >
              Close
            </Button>
          </div>
        </div>
      ) : null}

      {isAddDrawer && (
        <AddSnippetDrawer
          variablesData={variablesData}
          handleCreateSubmit={handleCreateSubmit}
          handleEditorContentChange={handleEditorContentChange}
          setIsSnippetList={setIsSnippetList}
          setIsAddDrawer={setIsAddDrawer}
        />
      )}

      {snippet.status === "success" && isEditDrawer && (
        <EditSnippetDrawer
          snippet={snippet}
          variablesData={variablesData}
          handleEditorContentChange={handleEditorContentChange}
          handleEditSubmit={handleEditSubmit}
          setIsEditDrawer={setIsEditDrawer}
          setIsSnippetList={setIsSnippetList}
        />
      )}
    </>
  );
};

export default SnippetsDrawer;
