import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Card, Checkbox, Button } from "antd";
import { FilePdfOutlined, DownloadOutlined } from "@ant-design/icons";

import {
  getDocumentsAction,
  getDocumentsZipAction,
} from "store/documents/documents.actions";
import moment from "moment";

import "./styles.scss";
import { documentEnums, formatBytes } from "./util";

const Documents = (props) => {
  const { documentList } = props.documents;
  const {
    test: { _id: test_id },
  } = props.tests;

  const [documentsToDownload, setDocumentsToDownload] = useState([]);

  useEffect(() => {
    async function f() {
      await props.getDocumentsAction(test_id);
    }
    f();
  }, []);

  const onDocumentSelect = (documentUrls) => {
    setDocumentsToDownload(documentUrls);
  };

  const handleDownloadClicked = () => {
    documentsToDownload.forEach((url) => {
      window.open(url);
    });
  };

  const handleDownloadAllDocuments = () => {
    const { documentList } = props.documents;

    let filePaths = [];
    Object.keys(documentList.data).forEach((doc) => {
      filePaths.push(documentList.data[doc]["filePath"]);
    });

    props.getDocumentsZipAction(filePaths, test_id);
  };

  return (
    <div className="page-documents">
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <div>
            <Card
              title="Documents"
              extra={
                <span
                  className="green cursor-pointer"
                  onClick={handleDownloadAllDocuments}
                >
                  <DownloadOutlined /> Download all
                </span>
              }
            >
              <div className="d-flex">
                <Checkbox.Group onChange={onDocumentSelect}>
                  <div className="d-flex">
                    {documentList.status === "success" &&
                      Object.keys(documentList.data).map((d, i) => {
                        const doc = documentList.data;
                        return (
                          <div className="document-box" key={i}>
                            <Checkbox value={doc[d]["file"]}>
                              <div className="document-box-inner d-flex flex-wrap cursor-pointer ">
                                <div className="document-icon">
                                  <FilePdfOutlined
                                    style={{ fontSize: "18px" }}
                                  />
                                </div>
                                <div className="document-text">
                                  <div className="text_1">
                                    {documentEnums[d]}
                                  </div>
                                  <div className="text_2">
                                    <span>
                                      {moment(doc[d]["createdAt"]).format("ll")}
                                    </span>
                                    {doc[d]["size"] && (
                                      <span>
                                        {" "}
                                        - {formatBytes(doc[d]["size"])}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Checkbox>
                          </div>
                        );
                      })}
                  </div>
                </Checkbox.Group>
              </div>
            </Card>
          </div>
        </Col>
        <div>
          <Button
            style={{ marginLeft: "16px" }}
            type="primary"
            icon={<DownloadOutlined />}
            onClick={handleDownloadClicked}
            disabled={documentsToDownload.length === 0}
          >
            Download
          </Button>
        </div>
      </Row>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    system: state.system,
    tests: state.tests,
    documents: state.documents,
  };
}

export default connect(mapStateToProps, {
  getDocumentsAction,
  getDocumentsZipAction,
})(Documents);
