import React, { useEffect, useState } from "react";
import { Col, Card, Button, Checkbox, Modal } from "antd";
import { EyeOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import cn from "classnames";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import { CheckOutlined, SyncOutlined } from "@ant-design/icons";
import { editorSettings, parserHelper } from "../../util";

import { debounce } from "lodash";
import AutoSave from "core/components/AutoSave";

const { confirm } = Modal;

const InterpretingEditors = (props) => {
  const {
    test,
    templateData,
    variablesData,
    preview,
    draft,
    showPreview,
    setShowPreview,
    showSnippetDrawer,
    setShowSnippetDrawer,
    handleGetSnippets,
    injectingToType,
    snippetInjectedContent,
    handleSignOff,
    handleSeePreview,
    handleInterpretationDraft,
    customFlags,
  } = props;
  const [editorVariables, originalVariables] = variablesData;

  const [completionSectionEntities, setCompletionSectionEntities] = useState(
    () => {
      if (draft.status === "success") {
        return { ...draft.data.data };
      }
      return getParsedCompletionSection(templateData.completion_sections);
    }
  );
  const [isReviewed, setIsReviewed] = useState(false);
  const [editorConfig] = useState(
    editorSettings(editorVariables.convertedInterpretingVariables)
  );
  const [focusedEditor, setFocusedEditor] = useState("");

  function getParsedCompletionSection(data) {
    let res = {};
    Object.keys(data).map((k) => {
      res[k] = {
        ...data[k],
        text: parserHelper(data[k]["text"], originalVariables),
      };
      return k;
    });
    return res;
  }

  // editor handlers
  const handleOnReady = (editor) => {
    editor &&
      editor.editing.view.change((writer) => {
        writer.setStyle(
          "min-height",
          "80px",
          editor.editing.view.document.getRoot()
        );
      });
  };

  // const handleEditorBlur = () => {};
  const handleEditorFocus = (type) => {
    if (injectingToType === type && showSnippetDrawer) return;

    setFocusedEditor(type);

    setShowSnippetDrawer(true);
    handleGetSnippets(type);
  };

  // const handleSnippetGet = (type) => {
  //   setShowSnippetDrawer(true);
  //   handleGetSnippets(type);
  // };

  const handleCompletionTextChange = (key, text) => {
    const parsedText = parserHelper(text, originalVariables);

    const entityKey = key;
    const updatedData = Object.assign({}, completionSectionEntities);
    updatedData[entityKey] = {
      ...updatedData[entityKey],
      text: parsedText,
    };
    setCompletionSectionEntities(updatedData);
    handleInterprationDraftData(updatedData);
  };

  const handleInterprationDraftData = (data) => {
    handleInterpretationDraft(props.templateData._id, data);
  };

  const handleTextChange = (key, text) => {
    handleCompletionTextChange(key, text);
  };
  const debouncedHandleTextChange = debounce(handleTextChange, 500);

  useEffect(() => {
    if (injectingToType && snippetInjectedContent) {
      handleSnippetInjection(injectingToType, snippetInjectedContent);
    }
  }, [snippetInjectedContent]);

  const handleSnippetInjection = (key, text) => {
    if (!text) return;
    const parsedText = parserHelper(text, originalVariables);

    const entityKey = key.toLowerCase();
    const updatedData = Object.assign({}, completionSectionEntities);
    updatedData[entityKey] = {
      ...updatedData[entityKey],
      text: updatedData[entityKey]["text"] + parsedText,
    };
    setCompletionSectionEntities(updatedData);
  };

  const handleSignOffConfirm = (e) => {
    e.stopPropagation();
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: test.ip_signoff
        ? "You are trying to edit a digitally signed interpretation. Any changes made to this interpretation will not reflect in the report unless you sign it again. Would you like to proceed?"
        : "Your interpretation report will be generated after confirmation and be available for download in the documents tab of this study.",
      okText: "Sign off",

      cancelText: "Cancel",
      onOk() {
        handleSignOff(completionSectionEntities);
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };

  return (
    <>
      {completionSectionEntities["findings"] &&
        completionSectionEntities["findings"]["selected"] === true && (
          <Col span={24}>
            <Card
              title="Findings"
              extra={
                <>
                  {draft.status === "success" &&
                    injectingToType === "FINDINGS" && (
                      <AutoSave delay="500">
                        <div className="badge-autosave">
                          <SyncOutlined spin /> Saving
                        </div>
                      </AutoSave>
                    )}
                </>
              }
            >
              <div
                className={cn("interpreting-editor", {
                  "focused-editor": focusedEditor === "FINDINGS",
                })}
              >
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    ...editorConfig,
                    placeholder:
                      "Write your findings here. Click for snippets to appear.",
                  }}
                  data={completionSectionEntities["findings"]["text"]}
                  onReady={(editor) => {
                    handleOnReady(editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    debouncedHandleTextChange("findings", data);
                  }}
                  onFocus={(event, editor) => {
                    handleEditorFocus("FINDINGS");
                  }}
                />
              </div>
            </Card>
          </Col>
        )}

      {completionSectionEntities["diagnosis"] &&
        completionSectionEntities["diagnosis"]["selected"] === true && (
          <Col span={24}>
            <Card
              title="Diagnosis"
              extra={
                <>
                  {draft.status === "success" &&
                    injectingToType === "DIAGNOSIS" && (
                      <AutoSave delay="500">
                        <div className="badge-autosave">
                          <SyncOutlined spin /> Saving
                        </div>
                      </AutoSave>
                    )}
                </>
              }
            >
              <div
                className={cn("interpreting-editor", {
                  "focused-editor": focusedEditor === "DIAGNOSIS",
                })}
              >
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    ...editorConfig,
                    placeholder:
                      "Write your diagnosis here. Click for snippets to appear.",
                  }}
                  data={completionSectionEntities["diagnosis"]["text"]}
                  onReady={(editor) => {
                    handleOnReady(editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    debouncedHandleTextChange("diagnosis", data);
                  }}
                  onFocus={(event, editor) => {
                    handleEditorFocus("DIAGNOSIS");
                  }}
                />
              </div>
            </Card>
          </Col>
        )}

      {completionSectionEntities["recommendations"] &&
        completionSectionEntities["recommendations"]["selected"] === true && (
          <Col span={24}>
            <Card
              title="Recommendations"
              extra={
                <>
                  {draft.status === "success" &&
                    injectingToType === "RECOMMENDATIONS" && (
                      <AutoSave delay="500">
                        <div className="badge-autosave">
                          <SyncOutlined spin /> Saving
                        </div>
                      </AutoSave>
                    )}
                </>
              }
            >
              <div
                className={cn("interpreting-editor", {
                  "focused-editor": focusedEditor === "RECOMMENDATIONS",
                })}
              >
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    ...editorConfig,
                    placeholder:
                      "Write your recommendations here. Click for snippets to appear.",
                  }}
                  data={completionSectionEntities["recommendations"]["text"]}
                  onReady={(editor) => {
                    handleOnReady(editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    debouncedHandleTextChange("recommendations", data);
                  }}
                  onFocus={(event, editor) => {
                    handleEditorFocus("RECOMMENDATIONS");
                  }}
                />
              </div>
            </Card>
          </Col>
        )}

      <Col span={24}>
        <Checkbox
          name="reviewed"
          key={"reviewed"}
          onChange={() => setIsReviewed(!isReviewed)}
        >
          I have reviewed the auto-scored data
        </Checkbox>
      </Col>

      {showPreview && preview.status === "success" && (
        <Modal
          title="Report Preview"
          centered
          visible={showPreview}
          onOk={() => setShowPreview(false)}
          onCancel={() => setShowPreview(false)}
          width={"50%"}
        >
          <iframe
            title="report-preview"
            width="100%"
            height="800px"
            srcDoc={preview.data}
          ></iframe>
        </Modal>
      )}

      {/* <div className="bottom-right-fixed">
        <div className="mt-4"> */}
      <div className="ml-4">
        <div className="">
          <span
            className="see-preview-container"
            onClick={() => handleSeePreview(completionSectionEntities)}
          >
            <EyeOutlined /> Preview
          </span>
          <Button
            className="ml-4"
            type="primary"
            icon={<CheckOutlined />}
            disabled={!isReviewed}
            onClick={handleSignOffConfirm}
          >
            Sign off
          </Button>
        </div>
      </div>
    </>
  );
};

export default InterpretingEditors;
