import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, notification } from "antd";

import { CheckCircleOutlined } from "@ant-design/icons";
import CircleSpinner from "core/components/CircleSpinner";

import {
  getCategoriesAction,
  getTemplateAction,
  getTemplatesAction,
  editTemplateAction,
  deleteTemplateAction,
  createTemplateAction,
  clearTemplateAction,
  makeTemplateFromShellAction,
  getSnippetsAction,
  getSnippetAction,
  editSnippetAction,
  deleteSnippetAction,
  createSnippetAction,
  getVariablesAction,
  handleSeePreviewAction,
  handleSignOffAction,
  draftInterpretationAction,
  clearDraftAction,
} from "store/evaluation/evaluation.actions";

import InterpretationSection from "../components/Interpreting/InterpretingSection";
import TemplatingSection from "../components/Templating/TemplatingSection";
import { getAvailableSection, parserHelper } from "../util";

import "../styles.scss";

function Interpretation(props) {
  const { test } = props.tests;
  const {
    test: { _id: test_id, unit, custom_flags },
  } = props.tests;

  const {
    templates,
    template,
    snippets,
    snippet,
    variables,
    signOff,
    preview,
    draft,
  } = props.evaluation;

  const [mode, setMode] = useState("interpreting");
  const [isCreateNewTemplate, setIsCreateNewTemplate] = useState(false);
  const [showSnippetDrawer, setShowSnippetDrawer] = useState(false);

  const [selectedTemplate, setSelectedTemplate] = useState(() => {
    if (template.status === "success") {
      return template.data._id;
    }
  });

  useEffect(() => {
    async function f() {
      // await props.getCategoriesAction(unit);
      await props.getTemplatesAction();
      // await props.getVariablesAction(test_id);
    }
    f();
  }, []);

  // useEffect(() => {
  //   if (signOff.status === "success") {
  //     notification.success({
  //       message: "Sign off successful",
  //       description:
  //         "You can find the signed off report under the Documents tab shortly",
  //       placement: "bottomRight",
  //       icon: <CheckCircleOutlined style={{ color: "#83c8bc" }} />,
  //     });
  //   }
  // }, [signOff.status]);

  // templates fetch
  const handleTemplateSelect = (template_id) => {
    setSelectedTemplate(template_id);
    if (template_id === "create-new-template") {
      setIsCreateNewTemplate(true);
      props.clearTemplateAction();
      setMode("templating");
    } else {
      if (isCreateNewTemplate) setIsCreateNewTemplate(false);
    }
    props.getTemplateAction(template_id);
    if (draft.status === "success") {
      props.clearDraftAction();
    }
  };
  const handleTemplateEditClicked = (e, id) => {
    if (showSnippetDrawer) {
      setShowSnippetDrawer(false);
    }
    props.getTemplateAction(id);
    setMode("templating");
  };
  const handleMakeTemplateFromShell = (shellTemplateData) => {
    props.makeTemplateFromShellAction(shellTemplateData);
  };

  // template crud operations
  const handleCreateNewTemplate = (templateData) => {
    const template = props.createTemplateAction(templateData);
    props.clearTemplateAction();

    if (template) {
      notification.success({
        message: "Created template successfully",
      });
    }
    setMode("interpreting");
  };
  const handleEditTemplate = async (_id, data) => {
    const res = await props.editTemplateAction(_id, data);

    if (!res.error) {
      notification.success({
        message: "Updated template successfully",
      });
      props.clearTemplateAction();
      setMode("interpreting");
    } else {
      notification.error({
        message: "Something went wrong",
      });
    }
  };
  const handleTemplateDeleteClicked = (e, id) => {
    e.stopPropagation();
    props.deleteTemplateAction(id);
    props.clearTemplateAction();
  };

  // snippets fetch
  const handleGetSnippets = (type) => {
    props.getSnippetsAction(type);
  };
  const handleGetSnippet = (id) => {
    props.getSnippetAction(id);
  };

  // snippets crud operations
  const handleSnippetEditClicked = (_id, data) => {
    props.editSnippetAction(_id, data);
  };
  const handleSnippetDeleteClicked = (e, _id) => {
    props.deleteSnippetAction(_id);
  };
  const handleCreateSnippet = (data) => {
    props.createSnippetAction({ ...data, centre: unit._id });
  };

  // general operations
  const handleCancelClicked = () => {
    props.clearTemplateAction();
    setMode("interpreting");
  };

  const composeInterpretationData = (
    defaultSectionEntities,
    completionSectionEntities
  ) => {
    const availableDefaultSectionData = getAvailableSection(
      defaultSectionEntities
    );
    const availableCompletionSectionData = getAvailableSection(
      completionSectionEntities
    );

    let defaultObj = {};
    for (let entity in availableDefaultSectionData) {
      defaultObj[entity] = parserHelper(
        availableDefaultSectionData[entity]["text"],
        variables.data[1]
      );
    }

    let completionObj = {};
    for (let entity in availableCompletionSectionData) {
      completionObj[entity] = availableCompletionSectionData[entity]["text"];
    }

    return {
      ...defaultObj,
      ...completionObj,
    };
  };

  // signoff
  const handleSignOff = async (completionSectionEntitiesData) => {
    const { template } = props.evaluation;
    const {
      test: { _id: test_id },
    } = props.tests;

    const data = composeInterpretationData(
      template.data.default_sections,
      completionSectionEntitiesData
    );

    const res = await props.handleSignOffAction(template.data._id, {
      ...data,
      test: test_id,
    });

    if (draft.status === "success") {
      props.clearDraftAction();
    }

    if (!res.error) {
      notification.success({
        message: "Sign off successful",
        description:
          "You can find the signed off report under the Documents tab shortly",
        placement: "bottomRight",
        icon: <CheckCircleOutlined style={{ color: "#83c8bc" }} />,
      });
    }
  };

  // preview
  const handleSeePreview = (completionSectionEntitiesData?) => {
    const { template } = props.evaluation;
    const data = composeInterpretationData(
      template.data.default_sections,
      completionSectionEntitiesData || template.data.completion_sections
    );

    props.handleSeePreviewAction(template.data._id, {
      ...data,
    });
  };

  const handleInterpretationDraft = (template_id, data) => {
    props.draftInterpretationAction(template_id, data);
  };

  if (templates.status !== "success") {
    return (
      <Row gutter={[32, 32]}>
        <Col span={24} className="mt-5 center">
          <CircleSpinner />
        </Col>
      </Row>
    );
  }

  return (
    <div className="page-interpretation">
      {mode === "interpreting" &&
        templates.status === "success" &&
        variables.status === "success" && (
          <>
            <InterpretationSection
              test={test}
              templates={templates}
              template={template}
              snippets={snippets}
              snippet={snippet}
              variables={variables}
              preview={preview}
              signOff={signOff}
              draft={draft}
              selectedTemplate={selectedTemplate}
              handleTemplateSelect={handleTemplateSelect}
              handleTemplateEditClicked={handleTemplateEditClicked}
              handleTemplateDeleteClicked={handleTemplateDeleteClicked}
              handleGetSnippets={handleGetSnippets}
              handleGetSnippet={handleGetSnippet}
              handleCreateSnippet={handleCreateSnippet}
              handleSnippetEditClicked={handleSnippetEditClicked}
              handleSnippetDeleteClicked={handleSnippetDeleteClicked}
              handleSignOff={handleSignOff}
              handleSeePreview={handleSeePreview}
              showSnippetDrawer={showSnippetDrawer}
              setShowSnippetDrawer={setShowSnippetDrawer}
              handleInterpretationDraft={handleInterpretationDraft}
              customFlags={custom_flags}
            />
          </>
        )}

      {mode === "templating" &&
        templates.status === "success" &&
        variables.status === "success" && (
          <>
            <TemplatingSection
              templates={templates}
              template={template}
              variables={variables}
              isCreateNewTemplate={isCreateNewTemplate}
              handleMakeTemplateFromShell={handleMakeTemplateFromShell}
              handleCreateNewTemplate={handleCreateNewTemplate}
              handleEditTemplate={handleEditTemplate}
              handleCancelClicked={handleCancelClicked}
              setShowSnippetDrawer={setShowSnippetDrawer}
              handleSeePreview={handleSeePreview}
              setMode={setMode}
            />
          </>
        )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    system: state.system,
    tests: state.tests,
    evaluation: state.evaluation,
  };
}

export default connect(mapStateToProps, {
  getCategoriesAction,
  getTemplatesAction,
  getTemplateAction,
  editTemplateAction,
  deleteTemplateAction,
  createTemplateAction,
  clearTemplateAction,
  makeTemplateFromShellAction,
  getSnippetsAction,
  getSnippetAction,
  editSnippetAction,
  deleteSnippetAction,
  createSnippetAction,
  getVariablesAction,
  handleSeePreviewAction,
  handleSignOffAction,
  draftInterpretationAction,
  clearDraftAction,
})(Interpretation);
