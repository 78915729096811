import React from "react";
import { Row, Col } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import CreateNewTemplate from "./CreateNewTemplate";
import TemplateEditors from "./TemplateEditors";
import CircleSpinner from "core/components/CircleSpinner";

import { groupTemplatesByCategory } from "containers/Evaluation/util";

const TemplatingSection = (props) => {
  const {
    templates,
    template,
    variables,
    isCreateNewTemplate,
    handleMakeTemplateFromShell,
    handleCreateNewTemplate,
    handleEditTemplate,
    handleCancelClicked,
    handleSeePreview,
    setShowSnippetDrawer,
    setMode,
  } = props;

  const groupedTemplates = groupTemplatesByCategory(templates.data);

  return (
    <Row gutter={[32, 32]}>
      <Col span={24}>
        {/* <div className="templating-section-close-icon float-right"> */}
        <div className="float-right">
          <CloseOutlined
            onClick={() => {
              setMode("interpreting");
              setShowSnippetDrawer(false);
            }}
          />
        </div>
      </Col>

      {template.status === "initialized" && (
        <Col span={24} className="mt-5 center">
          <CircleSpinner />
        </Col>
      )}
      {templates.status === "success" && isCreateNewTemplate && (
        <CreateNewTemplate
          templatesData={groupedTemplates}
          handleMakeTemplateFromShell={handleMakeTemplateFromShell}
        />
      )}

      {templates.status === "success" && template.status === "success" && (
        <TemplateEditors
          template={template}
          templateData={template.data}
          variablesData={variables.data}
          isCreateNewTemplate={isCreateNewTemplate}
          handleCreateNewTemplate={handleCreateNewTemplate}
          handleEditTemplate={handleEditTemplate}
          handleSeePreview={handleSeePreview}
          handleCancelClicked={handleCancelClicked}
        />
      )}
    </Row>
  );
};

export default TemplatingSection;
