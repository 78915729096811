import React, { useState } from "react";
import moment from "moment";
import { Timeline } from "antd";
import { eventValueMap } from "../constants";
import "../styles.scss";
import { useSpring, animated } from "@react-spring/web";

function TimelineEvent(props) {
  const [display, setDisplay] = useState(false);
  const { test, ti, isPtTimeZone } = props;

  const springProps = useSpring({
    opacity: display ? 1 : 0,
    config: {
      duration: 500,
    },
  });

  const renderSubText = (event, meta) => {
    if (event === "test_created" && meta && meta.first_name && meta.last_name) {
      return `by ${meta.first_name} ${meta.first_name}`;
    }
    return null;
  };

  return (
    <>
      <div className="timeline-item">
        <div className="timeline-item-date">
          {isPtTimeZone
            ? moment.unix(ti.timestamp).format("h:mm A")
            : moment
                .unix(ti.timestamp)
                .utcOffset(test.recordings[0].start_utc_offset)
                .format("h:mm A")}
        </div>
        <Timeline.Item
          className={
            ti.refToSecondary.length > 0 && !display
              ? "secondary-timeline-item"
              : ""
          }
          color={eventValueMap[ti.event].dotColor}
        >
          <div className="timeline-item-text">
            <div className="text_1">
              {eventValueMap[ti.event].text(ti.meta)}
            </div>
            <div className="text_2">{renderSubText(ti.event, ti.meta)}</div>

            <div onClick={() => setDisplay(!display)}>
              {ti.refToSecondary.length > 0 ? (
                !display ? (
                  <div className="text_3">
                    {ti.refToSecondary.length} more items.{" "}
                    <span className="toggle-btn">Show</span>
                  </div>
                ) : (
                  <div className="text_3">
                    All items. <span className="toggle-btn">Hide</span>
                  </div>
                )
              ) : null}
            </div>
          </div>
        </Timeline.Item>
      </div>
      {display && (
        <animated.div style={springProps}>
          {ti.refToSecondary.map((e, i) => (
            <div key={i} className="timeline-item">
              <div className="timeline-item-date">
                {isPtTimeZone
                  ? moment.unix(e.timestamp).format("h:mm A")
                  : moment
                      .unix(ti.timestamp)
                      .utcOffset(test.recordings[0].start_utc_offset)
                      .format("h:mm A")}
              </div>
              <Timeline.Item color={eventValueMap[e.event].dotColor}>
                <div className="timeline-item-text">
                  <div className="text_1">
                    {eventValueMap[e.event].text(e.meta)}
                  </div>
                </div>
              </Timeline.Item>
            </div>
          ))}
        </animated.div>
      )}
    </>
  );
}

export default TimelineEvent;
