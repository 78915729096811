import { uninitialized, initialized, success, error } from "./evaluation.types";

import {
  TEMPLATES_LIST_REQUEST,
  TEMPLATES_LIST_RESPONSE,
  TEMPLATE_REQUEST,
  TEMPLATE_RESPONSE,
  EDIT_TEMPLATE_REQUEST,
  EDIT_TEMPLATE_RESPONSE,
  DELETE_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_RESPONSE,
  CREATE_TEMPLATE_REQUEST,
  CREATE_TEMPLATE_RESPONSE,
  CLEAR_TEMPLATE,
  MAKE_TEMPLATE_FROM_SHELL_REQUEST,
  MAKE_TEMPLATE_FROM_SHELL_RESPONSE,
  SNIPPETS_LIST_REQUEST,
  SNIPPETS_LIST_RESPONSE,
  SNIPPET_REQUEST,
  SNIPPET_RESPONSE,
  EDIT_SNIPPET_REQUEST,
  EDIT_SNIPPET_RESPONSE,
  DELETE_SNIPPET_REQUEST,
  DELETE_SNIPPET_RESPONSE,
  CREATE_SNIPPET_REQUEST,
  CREATE_SNIPPET_RESPONSE,
  VARIABLES_LIST_REQUEST,
  VARIABLES_LIST_RESPONSE,
  SIGNOFF_REQUEST,
  SIGNOFF_RESPONSE,
  PREVIEW_REQUEST,
  PREVIEW_RESPONSE,
  DRAFT_INTERPRETATION_REQUEST,
  DRAFT_INTERPRETATION_RESPONSE,
  CLEAR_DRAFT,
} from "./evaluation.types";

const initialState = {
  templates: {
    status: uninitialized,
    error: null,
    data: [],
  },
  template: {
    status: uninitialized,
    error: null,
    data: null,
  },
  snippets: {
    status: uninitialized,
    error: null,
    data: [],
  },
  snippet: {
    status: uninitialized,
    error: null,
    data: null,
  },
  variables: {
    status: uninitialized,
    error: null,
    data: [],
  },
  signOff: {
    status: uninitialized,
    error: null,
    data: null,
  },
  preview: {
    status: uninitialized,
    error: null,
    data: null,
  },
  draft: {
    status: uninitialized,
    error: null,
    data: null,
  },
};

export function evaluationReducer(state = initialState, action) {
  switch (action.type) {
    case TEMPLATES_LIST_REQUEST:
      return {
        ...state,
        templates: {
          ...state.templates,
          status: initialized,
        },
      };
    case TEMPLATES_LIST_RESPONSE:
      if (action.payload.error) {
        return {
          ...state,
          templates: {
            ...state.templates,
            status: error,
            error: action.payload.error,
          },
        };
      }
      return {
        ...state,
        templates: {
          ...state.templates,
          status: success,
          error: null,
          data: action.payload.templates,
        },
      };

    case TEMPLATE_REQUEST:
      return {
        ...state,
        template: {
          ...state.template,
          status: initialized,
        },
      };
    case TEMPLATE_RESPONSE:
      if (action.payload.error) {
        return {
          ...state,
          template: {
            ...state.template,
            status: error,
            error: action.payload.error,
          },
        };
      }
      return {
        ...state,
        template: {
          ...state.template,
          status: success,
          data: action.payload.template,
        },
      };

    case EDIT_TEMPLATE_REQUEST:
      return {
        ...state,
        templates: {
          ...state.templates,
          status: initialized,
        },
      };
    case EDIT_TEMPLATE_RESPONSE:
      if (action.payload.error) {
        return {
          ...state,
          templates: {
            ...state.templates,
            status: error,
            error: action.payload.error,
          },
        };
      }

      let stateTemplatesDataCopy = [...state.templates.data];
      const templateIndex = state.templates.data.findIndex(
        (t) => t._id === action.payload.template._id
      );
      stateTemplatesDataCopy[templateIndex] = action.payload.template;
      return {
        ...state,
        templates: {
          ...state.templates,
          data: stateTemplatesDataCopy,
          status: success,
        },
      };

    case DELETE_TEMPLATE_REQUEST:
      return {
        ...state,
        templates: {
          ...state.templates,
          status: initialized,
        },
      };
    case DELETE_TEMPLATE_RESPONSE:
      if (action.payload.error) {
        return {
          ...state,
          templates: {
            ...state.templates,
            status: error,
            error: action.payload.error,
          },
        };
      }

      return {
        ...state,
        templates: {
          ...state.templates,
          data: state.templates.data.filter(
            (t) => t._id !== action.payload.template_id
          ),
          status: success,
        },
      };

    case CREATE_TEMPLATE_REQUEST:
      return {
        ...state,
        templates: {
          ...state.templates,
          status: initialized,
        },
      };
    case CREATE_TEMPLATE_RESPONSE:
      if (action.payload.error) {
        return {
          ...state,
          templates: {
            ...state.templates,
            status: error,
            error: action.payload.error,
          },
        };
      }
      return {
        ...state,
        templates: {
          data: [...state.templates.data, action.payload.template],
          status: success,
        },
      };

    case CLEAR_TEMPLATE:
      return {
        ...state,
        template: {
          ...state.template,
          status: uninitialized,
          error: null,
          data: null,
        },
      };

    case MAKE_TEMPLATE_FROM_SHELL_REQUEST:
      return {
        ...state,
      };
    case MAKE_TEMPLATE_FROM_SHELL_RESPONSE:
      if (action.payload.error) {
        return {
          ...state,
          template: {
            ...state.template,
            status: error,
            error: action.payload.error,
          },
        };
      }
      return {
        ...state,
        template: {
          ...state.template,
          status: success,
          data: action.payload.shellTemplateData,
        },
      };

    case SNIPPETS_LIST_REQUEST:
      return {
        ...state,
        snippets: {
          ...state.snippets,
          status: initialized,
        },
      };
    case SNIPPETS_LIST_RESPONSE:
      if (action.payload.error) {
        return {
          ...state,
          snippets: {
            ...state.snippets,
            status: error,
            error: action.payload.error,
          },
        };
      }
      return {
        ...state,
        snippets: {
          ...state.snippets,
          status: success,
          error: null,
          data: action.payload.snippets,
        },
      };

    case CREATE_SNIPPET_REQUEST:
      return {
        ...state,
        snippets: {
          ...state.snippets,
          status: initialized,
        },
      };
    case CREATE_SNIPPET_RESPONSE:
      if (action.payload.error) {
        return {
          ...state,
          snippets: {
            ...state.snippets,
            status: error,
            error: action.payload.error,
          },
        };
      }
      return {
        ...state,
        snippets: {
          ...state.snippets,
          data: [...state.snippets.data, action.payload.snippet],
          status: success,
        },
      };

    case SNIPPET_REQUEST:
      return {
        ...state,
        snippet: {
          ...state.snippet,
          status: initialized,
        },
      };
    case SNIPPET_RESPONSE:
      if (action.payload.error) {
        return {
          ...state,
          snippet: {
            ...state.snippet,
            status: error,
            error: action.payload.error,
          },
        };
      }
      return {
        ...state,
        snippet: {
          ...state.snippet,
          status: success,
          data: action.payload.snippet,
        },
      };

    case EDIT_SNIPPET_REQUEST:
      return {
        ...state,
        snippets: {
          ...state.snippets,
          status: initialized,
        },
      };

    case EDIT_SNIPPET_RESPONSE:
      if (action.payload.error) {
        return {
          ...state,
          snippets: {
            ...state.snippets,
            status: error,
            error: action.payload.error,
          },
        };
      }

      let stateSnippetsDataCopy = [...state.snippets.data];
      const snippetIndex = state.snippets.data.findIndex(
        (s) => s._id === action.payload.snippet._id
      );
      stateSnippetsDataCopy[snippetIndex] = action.payload.snippet;

      return {
        ...state,
        snippets: {
          ...state.snippets,
          data: stateSnippetsDataCopy,
          status: success,
        },
      };

    case DELETE_SNIPPET_REQUEST:
      return {
        ...state,
        snippets: {
          ...state.snippets,
          status: initialized,
        },
      };

    case DELETE_SNIPPET_RESPONSE:
      if (action.payload.error) {
        return {
          ...state,
          snippets: {
            ...state.snippets,
            status: error,
            error: action.payload.error,
          },
        };
      }

      return {
        ...state,
        snippets: {
          ...state.snippets,
          data: state.snippets.data.filter(
            (t) => t._id !== action.payload.snippet_id
          ),
          status: success,
        },
      };

    case VARIABLES_LIST_REQUEST:
      return {
        ...state,
        variables: {
          ...state.variables,
          status: initialized,
        },
      };
    case VARIABLES_LIST_RESPONSE:
      if (action.payload.error) {
        return {
          ...state,
          variables: {
            ...state.variables,
            status: error,
            error: action.payload.error,
          },
        };
      }
      return {
        ...state,
        variables: {
          ...state.variables,
          status: success,
          error: null,
          data: [
            action.payload.variables[0],
            action.payload.variables[1],
            action.payload.variables[2],
          ],
        },
      };

    case SIGNOFF_REQUEST:
      return {
        ...state,
        signOff: {
          ...state.signOff,
          status: initialized,
        },
      };
    case SIGNOFF_RESPONSE:
      if (action.payload.error) {
        return {
          ...state,
          signOff: {
            ...state.signOff,
            status: error,
            error: action.payload.error,
          },
        };
      }
      return {
        ...state,
        signOff: {
          ...state.signOff,
          status: success,
          error: null,
          data: action.payload.signOff,
        },
      };

    case PREVIEW_REQUEST:
      return {
        ...state,
        preview: {
          ...state.preview,
          status: initialized,
        },
      };
    case PREVIEW_RESPONSE:
      if (action.payload.error) {
        return {
          ...state,
          preview: {
            ...state.preview,
            status: error,
            error: action.payload.error,
          },
        };
      }
      return {
        ...state,
        preview: {
          ...state.preview,
          status: success,
          error: null,
          data: action.payload.preview,
        },
      };

    case DRAFT_INTERPRETATION_REQUEST:
      return {
        ...state,
        draft: {
          ...state.draft,
          status: initialized,
        },
      };
    case DRAFT_INTERPRETATION_RESPONSE:
      if (action.payload.error) {
        return {
          ...state,
          draft: {
            ...state.draft,
            status: error,
            error: action.payload.error,
          },
        };
      }
      return {
        ...state,
        draft: {
          ...state.draft,
          status: success,
          error: null,
          data: action.payload.data,
        },
      };

    case CLEAR_DRAFT:
      return {
        ...state,
        draft: {
          ...state.draft,
          status: uninitialized,
          error: null,
          data: null,
        },
      };

    default:
      return state;
  }
}
