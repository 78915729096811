import { parseCookie } from "core/util/cookieUtil";
import moment from "moment";

export function convertPatientBirthday(dateString: string): Date | null {
  if (dateString) {
    return moment.utc(dateString, moment.ISO_8601).toDate();
  }
  return null;
}

export function convertToPatientBirthday(
  date: Date | number | null
): string | null {
  if (date) {
    return moment.utc(date).format("YYYY-MM-DD");
  }
  return null;
}

export const userAuthObj = () => {
  if (window.location.hostname !== "localhost") {
    const cookie = parseCookie();

    if (cookie["x-auth-token"]) {
      const token = cookie["x-auth-token"];
      const service = cookie["x-signing-service"] || "HCP";
      if (token) {
        return { token, service };
      }
    } else {
      window.location.replace(`${HCP_DASHBOARD_URL}/logout`);
    }
  }

  return {
    token: "7ba9319e6bb74daeaa3e889b597a058fa617ea91537fd3a220ccf35e7e9a9dda",
    service: "HCP",
  };
};

export function manageTestId() {
  // 610e9c61e984da015f61a0ac
  const search = new URLSearchParams(window.location.search);
  let test_id = search.get("test_id");

  if (localStorage.getItem("test_id") === null) {
    localStorage.setItem("test_id", test_id);
  } else if (test_id === localStorage.getItem("test_id")) {
    test_id = localStorage.getItem("test_id");
  }
  if (test_id === null) {
    test_id = localStorage.getItem("test_id");
  }

  if (
    test_id !== null &&
    localStorage.getItem("test_id") !== null &&
    test_id !== localStorage.getItem("test_id")
  ) {
    localStorage.setItem("test_id", test_id);
  }

  return test_id;
}

export function parseISO(s: string): Date {
  return moment(s, moment.ISO_8601).toDate();
}
